import { Drawer, Input, InputGroup, Button, Panel, Modal, Table, TagPicker, IconButton, InputPicker, Checkbox } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import RefreshIcon from '@rsuite/icons/Reload';
import UserIcon from '@rsuite/icons/legacy/UserPlus';
import DeleteIcon from '@rsuite/icons/legacy/Trash';
import ExportIcon from '@rsuite/icons/Page';
import moment from 'moment';

import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

import './UserData.css';
import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { useMessageToaster } from './Messages';
import UserDrawerPage from './User/UserDrawerPage';
import DataObjects from './DataObjects';
import ToggleButton from './Controls/ToggleButton';

import Signup from './User/Signup';
import { useSelector } from 'react-redux';
import api from './api';
const { Column, HeaderCell, Cell } = Table;

const defaultColumns = [{
    key: 'title',
    label: 'Title',
    resizable: true,
    align: 'center'
},
{
    key: 'name',
    label: 'First name',
    resizable: true
},
{
    key: 'surname',
    label: 'Last name',
    resizable: true,
    width: 120
},
{
    key: 'email',
    label: 'Email',
    flexGrow: 1
},
{
    key: 'id_number',
    label: 'ID',
    width: 180
},
{
    key: 'location',
    label: 'Location',
    width: 210
},
{
    key: 'province',
    label: 'Province',
    width: 200,
    resizable: true
},
{
    key: 'has_ms',
    label: 'Has MS',
    width: 80,
    align: 'center',
    resizable: true
},
{
    key: 'year_diagnosed',
    label: 'Year diagnosed',
    width: 120
},
{
    key: 'reason',
    label: 'Reason',
    resizable: true,
    width: 180
},
{
    key: 'date_of_birth',
    label: 'Birthday',
    width: 120
},
{
    key: 'country_of_birth',
    label: 'Country',
    width: 180,
    resizable: true
},
{
    key: 'ethnicity',
    label: 'Ethnicity',
    width: 120,
    resizable: true
},
{
    key: 'history_ms',
    label: 'MS history',
    resizable: true
},
{
    key: 'live_with',
    label: 'Lives with',
    width: 200,
    resizable: true
},
{
    key: 'mobility',
    label: 'Mobility',
    width: 140,
    resizable: true
},
{
    key: 'driving',
    label: 'Driving',
    width: 260,
    resizable: true
},
{
    key: 'incontinence_issues',
    label: 'Incontinence',
    width: 240,
    resizable: true
},
{
    key: 'type_ms',
    label: 'MS type',
    width: 220,
    resizable: true
},
{
    key: 'phone_number',
    label: 'Cell no.',
    width: 130,
    resizable: true
},
{
    key: 'on_medical_plan',
    label: 'Medical aid',
    width: 240,
    resizable: true
},
{
    key: 'access_cronic_medication',
    label: 'Cronic medication',
    width: 120,
    align: 'center'
},
{
    key: 'goverment_clinic_hospital',
    label: 'Receives Clinic/hospital',
    width: 150,
    align: 'center'
},
{
    key: 'facility_recieved',
    label: 'Facility',
    resizable: true,
    width: 200
},
{
    key: 'on_dmt',
    label: 'uses DMT',
    width: 80,
    align: 'center'
},
{
    key: 'year_dmt_start',
    label: 'DMT start',
    width: 120
},

{
    key: 'medication_score',
    label: 'Medication score',
    resizable: true
}, {
    key: 'receive_newsletter',
    label: 'Recieve newsletter',
    width: 130,
    align: 'center',
    resizable: true
}, {
    key: 'contact_method',
    label: 'Preferred contact',
    width: 120
}, {
    key: 'contact_me',
    label: 'Contact me',
    align: 'center',
    resizable: true
}, {
    key: 'contact_reason',
    label: 'Contact reason',
    resizable: true,
    width: 140
}
];

function UserData() {
    const navigator = useNavigate();

    const { user } = useSelector((state) => state.auth);
    if (!user.management) {
        console.log('Here: ' + user.management);
        navigator('/');
    }
    const userCanExport = user.permissions.includes('Export');
    const create = user.permissions.includes('Create');
    const [updateUser] = useState(!user.permissions.includes('Update user'));

    const {
        showInfoMessage,
        showErrorMessage,
    } = useMessageToaster();
    const token = localStorage.getItem('token');

    const [newUser, setNewUser] = useState(false);

    const [tableLoading, setTableLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [filterData, setFilterData] = useState(tableData);
    // const [filterOpen, setFilterOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [columnKeys, setColumnKeys] = useState(['title', 'name', 'surname', 'email']);
    const [isOpen, setIsOpen] = useState(false);
    const columns = defaultColumns.filter(column => columnKeys.some(key => key === column.key));
    const [exportLoading, setExportLoading] = useState(false);
    const [exportAllColumn, setExportAllColumns] = useState(false);

    // Filters 
    const [searchValue, setSearchValue] = useState('');
    const [contact, setContact] = useState(false);
    const [hasMs, setHasMs] = useState(null);
    const [hasCronicAccess, setHasCronicAccess] = useState(null);
    const [govermentCH, setGovermentCH] = useState(null);
    const [newsletter, setNewsletter] = useState(false);

    const [title, setTitle] = useState('');
    const [province, setProvince] = useState('');
    const [country, setCountry] = useState('');
    const [reason, setReason] = useState('');
    const [race, setRace] = useState('');
    const [history, setHistory] = useState('');
    const [msType, setMsType] = useState('');
    const [livesWith, setLivesWith] = useState('');
    const [mobility, setMobility] = useState('');
    const [driving, setDriving] = useState('');
    const [incontinence, setIncontinence] = useState('');
    const [dmt, setDmt] = useState('');
    const [medicalAid, setMedicalAid] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [deleteUser, setDeleteUser] = useState('');

    const handleCloseDrawer = () => {
        setIsOpen(false);
    };

    const handleExpand = rowData => {
        const userData = { ...rowData };
        setSelectedUser(userData);
        console.log(userData);
        setIsOpen(true);
    };

    const handleConfirm = () => {
        fetch(api.baseURL + '/auth/removeuser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                user: deleteUser._id
            })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            return response.json();
        }).then(responseData => {
            console.log(responseData);
            showInfoMessage(responseData.message);
            setShowModal(false);
            tableRefresh();
        }).catch(error => {
            showErrorMessage(error.message);
            console.error(error);
        });
    };

    function handleDeleteUser(rowData) {
        setDeleteUser(rowData);
        setShowModal(true);
    }
    const handleCancel = () => {
        setShowModal(false);
        showInfoMessage('canceled');
    };

    function createCallback() {
        tableRefresh();
    }

    useEffect(() => {
        let filteredData = tableData.filter((item) => {
            if (hasMs != null && ((hasMs && !item.has_ms) || (!hasMs && item.has_ms))) {
                return false;
            }
            if (newsletter && !item.receive_newsletter) {
                return false;
            }
            if (hasCronicAccess != null && ((hasCronicAccess && !item.access_cronic_medication) || (!hasCronicAccess && item.access_cronic_medication))) {
                return false;
            }
            if (govermentCH != null && ((govermentCH && !item.goverment_clinic_hospital) || (!govermentCH && item.goverment_clinic_hospital))) {
                return false;
            }
            if (contact && !item.contact_me) {
                return false;
            }

            if (title && item.title !== title) {
                return false;
            }
            if (province && item.province !== province) {
                return false;
            }
            if (country && item.country_of_birth !== country) {
                return false;
            }
            if (reason && item.reason !== reason) {
                return false;
            }
            if (race && item.ethnicity !== race) {
                return false;
            }
            if (history && item.history_ms !== history) {
                return false;
            }
            if (msType && item.type_ms !== msType) {
                return false;
            }
            if (livesWith && item.live_with !== livesWith) {
                return false;
            }
            if (mobility && item.mobility !== mobility) {
                return false;
            }
            if (driving && item.driving !== driving) {
                return false;
            }
            if (incontinence && item.incontinence_issues !== incontinence) {
                return false;
            }
            if (dmt && item.on_dmt !== dmt) {
                return false;
            }
            if (medicalAid && item.on_medical_plan !== medicalAid) {
                return false;
            }

            if (searchValue) {
                let emailMatch = item.email && item.email.toLowerCase().includes(searchValue.toLowerCase());
                let nameMatch = item.name && item.name.toLowerCase().includes(searchValue.toLowerCase());
                let surnameMatch = item.surname && item.surname.toLowerCase().includes(searchValue.toLowerCase());
                let idNumberMatch = item.id_number && item.id_number.toLowerCase().includes(searchValue.toLowerCase());
                let locationMatch = item.location && item.location.toLowerCase().includes(searchValue.toLowerCase());
                let facilityMatch = item.facility_recieved && item.facility_recieved.toLowerCase().includes(searchValue.toLowerCase());
                let medicalMatch = item.on_medical_plan && item.on_medical_plan.toLowerCase().includes(searchValue.toLowerCase());
                let phoneMatch = item.phone_number && item.phone_number.toLowerCase().includes(searchValue.toLowerCase());

                if (!emailMatch && !nameMatch && !surnameMatch && !idNumberMatch && !locationMatch && !facilityMatch && !medicalMatch && !phoneMatch) {
                    return false;
                }
            }
            return true;
        });
        setFilterData(filteredData);
    }, [
        searchValue, tableData, contact, hasMs,
        hasCronicAccess, govermentCH, newsletter,
        title, province, reason, race, history,
        livesWith, mobility, driving, incontinence,
        dmt, medicalAid, msType, country
    ]);

    useEffect(() => {
        tableRefresh();

        const interval = setInterval(tableRefresh, 10000);
        return () => clearInterval(interval);
    }, [user]);

    function tableRefresh() {
        // setTableLoading(true);
        const token = localStorage.getItem('token');

        fetch(api.baseURL + '/users', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((data) => {
                setTableData(data);
                setTableLoading(false);
            })
            .catch((error) => console.log(error));
    };

    async function ExportToExcel() {
        try {
            setExportLoading(true);
            let worksheet;
            let headers;

            if (exportAllColumn) {
                worksheet = utils.json_to_sheet(filterData.map(row => {
                    // Exclude the specified columns
                    const newRow = { ...row };
                    delete newRow._id;
                    delete newRow._v;
                    delete newRow.password;

                    // Replace true/false with yes/no
                    for (const key in newRow) {
                        if (newRow[key] === true) {
                            newRow[key] = 'yes';
                        } else if (newRow[key] === false) {
                            newRow[key] = 'no';
                        }
                    }

                    return newRow;
                }));
                headers = columns
                    .filter(column => !['_id', '__v', 'password'].includes(column.key)) // Exclude specified columns from headers
                    .map(column => column.label);
            } else {
                worksheet = utils.json_to_sheet(
                    filterData.map(row => {
                        const newRow = {};
                        columns.forEach(column => {
                            // Exclude the specified columns
                            if (!['_id', '__v', 'password'].includes(column.key)) {
                                newRow[column.key] = row[column.key];

                                // Replace true/false with yes/no
                                if (newRow[column.key] === true) {
                                    newRow[column.key] = 'yes';
                                } else if (newRow[column.key] === false) {
                                    newRow[column.key] = 'no';
                                }
                            }
                        });
                        return newRow;
                    }),
                    {
                        header: columns
                            .filter(column => !['_id', '__v', 'password'].includes(column.key)) // Exclude specified columns from headers
                            .map(column => column.key)
                    }
                );
            }

            const workbook = utils.book_new();

            utils.book_append_sheet(workbook, worksheet, 'Table Data');

            const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(excelBlob, 'tableData.xlsx');
        } catch (error) {
            setExportLoading(false);
            showErrorMessage('Could not export data: ' + error);
        } finally {
            setTimeout(() => {
                setExportLoading(false);
            }, 5000);
        }
    }


    // async function ExportToExcel() {
    //     try {
    //         setExportLoading(true);
    //         let worksheet;
    //         let headers;

    //         if (exportAllColumn) {
    //             worksheet = utils.json_to_sheet(filterData);
    //             headers = columns.map((column) => column.label);
    //         } else {
    //             worksheet = utils.json_to_sheet(
    //                 filterData.map(row => {
    //                     const newRow = {};
    //                     columns.forEach(column => {
    //                         newRow[column.key] = row[column.key];
    //                     });
    //                     return newRow;
    //                 }),
    //                 { header: columns.map((column) => column.key) }
    //             );
    //         }

    //         const workbook = utils.book_new();

    //         utils.book_append_sheet(workbook, worksheet, 'Table Data');

    //         const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    //         const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         saveAs(excelBlob, 'tableData.xlsx');
    //     } catch (error) {
    //         setExportLoading(false);
    //         showErrorMessage('Could not export data: ' + error);
    //     } finally {
    //         setTimeout(() => {
    //             setExportLoading(false);
    //         }, 5000);
    //     }
    // }

    return (
        <div className="pageContainer">
            <Modal open={showModal} onClose={handleCancel}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete user: {deleteUser.name}'s account?</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCancel}
                        appearance="subtle">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm}
                        appearance="primary"
                        color="red">
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
            <Panel className='shadow-sm'
                collapsible
                style={{ padding: 0, maxHeight: '400px' }}
                header={(
                    <div className='panelHeader'>
                        Filter
                    </div>
                )}>
                <div className='filterOptionContainer'>
                    <div className='filterOptionsOne'>
                        Columns:
                        <TagPicker style={{ width: '100%', marginBottom: '10px' }}
                            data={defaultColumns}
                            labelKey='label'
                            valueKey='key'
                            value={columnKeys}
                            cleanable
                            onChange={setColumnKeys} />

                        Select Options:
                        <div className='dropdownContainer'>
                            <div className='inputContainer'>
                                <InputPicker placeholder='Title'
                                    style={{
                                        width: '80px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.title}
                                    onChange={setTitle} />

                                <InputPicker placeholder='Province'
                                    style={{
                                        width: '140px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.province}
                                    onChange={setProvince} />

                                <InputPicker placeholder='Country'
                                    style={{
                                        width: '200px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.countryList}
                                    onChange={setCountry} />

                                <InputPicker placeholder='reason'
                                    style={{
                                        width: '170px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.reason}
                                    onChange={setReason} />

                                <InputPicker placeholder='Ethnicity'
                                    style={{
                                        width: '110px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.race}
                                    onChange={setRace} />

                                <InputPicker placeholder='MS history'
                                    style={{
                                        width: '120px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.msHistory}
                                    onChange={setHistory} />



                                <InputPicker placeholder='Lives with'
                                    style={{
                                        width: '200px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.liveArray}
                                    onChange={setLivesWith} />

                                <InputPicker placeholder='Mobility'
                                    style={{
                                        width: '220px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.mobilityList}
                                    onChange={setMobility} />

                                <InputPicker placeholder='Driving'
                                    style={{
                                        width: '260px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.drivingList}
                                    onChange={setDriving} />

                                <InputPicker placeholder='Incontinence'
                                    style={{
                                        width: '280px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.IncontinenceList}
                                    onChange={setIncontinence} />

                                {/* <InputPicker placeholder='Ms type' */}
                                {/* data={DataObjects.} /> */}

                                <InputPicker placeholder='DMT use'
                                    style={{
                                        width: '120px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.dmtList}
                                    onChange={setDmt} />

                                <InputPicker placeholder='Medical aid'
                                    style={{
                                        width: '280px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.medicalAidList}
                                    onChange={setMedicalAid} />
                                <InputPicker placeholder='MS type'
                                    style={{
                                        width: '120px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        padding: 0
                                    }}
                                    data={DataObjects.msList}
                                    onChange={setMsType} />
                            </div>
                            <div className='filterButtonContainer'>
                                <ToggleButton value={hasMs}
                                    onChange={setHasMs}
                                    text='Has MS' />
                                <ToggleButton value={hasCronicAccess}
                                    onChange={setHasCronicAccess}
                                    text='Cronic medication access' />
                                <ToggleButton value={govermentCH}
                                    onChange={setGovermentCH}
                                    text='Goverment Clinic/Hospital' />
                                <Button appearance={newsletter ? 'primary' : 'default'}
                                    onClick={() => { setNewsletter(!newsletter) }}>
                                    Newsletter
                                </Button>
                                <Button appearance={contact ? 'primary' : 'default'}
                                    onClick={() => { setContact(!contact) }}>
                                    Contact
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='filterOptionsTwo'>
                        <div className='filterOptionButtonContainer'>
                            <p>
                                Controls:
                            </p>
                            <IconButton appearance='primary'
                                icon={<RefreshIcon />}
                                onClick={tableRefresh}>
                                Refresh table
                            </IconButton>
                            <IconButton icon={<UserIcon />}
                                disabled={!create}
                                onClick={() => { setNewUser(!newUser) }}>
                                Create user
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Panel>
            <div className='pageContentContainer'>

                <div className='additionalContainer shadow-sm'>
                    <div className='resultContainer'>
                        {filterData.length} results
                    </div>
                    <div className='searchContainer'>
                        {userCanExport ?
                            <div className='printButtonContainer'>
                                <Checkbox checked={exportAllColumn}
                                    onClick={() => { setExportAllColumns(!exportAllColumn) }}
                                    style={{ width: '200px' }}>
                                    Export all columns
                                </Checkbox>
                                <div style={{ width: '150px', display: 'flex', alignItems: 'center' }}>
                                    <IconButton appearance='primary'
                                        color='green'
                                        size='sm'
                                        icon={<ExportIcon />}
                                        loading={exportLoading}
                                        onClick={ExportToExcel}
                                        block>Export to Excel</IconButton>
                                </div>
                            </div> :
                            <></>
                        }
                        <InputGroup>
                            <Input placeholder='Search'
                                onChange={setSearchValue}
                                style={{ width: '200px' }} />
                            <InputGroup.Addon>
                                <SearchIcon />
                            </InputGroup.Addon>
                        </InputGroup>
                    </div>
                </div>

                <div className='tableContainer shadow-sm'>
                    <Table data={filterData}
                        fillHeight
                        loading={tableLoading}>
                        {columns.map(column => {
                            const { key, label, ...rest } = column;
                            return (
                                <Column {...rest} key={key}>
                                    <HeaderCell>{label}</HeaderCell>
                                    <Cell dataKey={key}>
                                        {rowData => {
                                            const value = rowData[key];

                                            if (moment(value, moment.ISO_8601, true).isValid()) {
                                                return moment(value).format('DD/MM/YYYY');
                                            } else if (typeof value === "boolean") {
                                                return value ? "Yes" : "No";
                                            } else {
                                                return value;
                                            }
                                        }}
                                    </Cell>
                                </Column>
                            );
                        })
                        }
                        {/* <Column flexGrow={1}>
                            <HeaderCell>Email</HeaderCell>
                            <Cell dataKey='email' />
                        </Column> */}
                        <Column width={100}
                            align='center'
                            verticalAlign='middle'>
                            <HeaderCell>Delete user</HeaderCell>
                            <Cell style={{
                                padding: 0
                            }}>
                                {rowData => (
                                    <IconButton appearance="link"
                                        color='red'
                                        size='xs'
                                        onClick={() => handleDeleteUser(rowData)}
                                        icon={<DeleteIcon />}
                                        style={{ border: '0px' }}>
                                    </IconButton>
                                )}
                            </Cell>
                        </Column>
                        <Column width={100} align='center'>
                            <HeaderCell>More info</HeaderCell>
                            <Cell>
                                {rowData => (
                                    <Button
                                        appearance='link'
                                        onClick={() => handleExpand(rowData)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            width: '100%'
                                        }}>
                                        expand
                                    </Button>
                                )}
                            </Cell>
                        </Column>
                    </Table>
                    {selectedUser && (
                        <UserDrawerPage open={isOpen}
                            id={selectedUser._id}
                            onClose={handleCloseDrawer}
                            update={tableRefresh}
                            readonly={updateUser}
                        />
                    )}
                    {newUser && (
                        <Drawer open={newUser}
                            onClose={() => { setNewUser(false); }}>
                            <Drawer.Header>New User</Drawer.Header>
                            <Drawer.Body>
                                <Signup createCallback={createCallback} />
                            </Drawer.Body>
                        </Drawer>
                    )}

                </div>
            </div>
        </div>
    );
}

export default UserData;