import './Navigation.css';
import { Navbar, Nav } from "rsuite";
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoggedOut } from '../store/loginSlice';
import { useSelector } from 'react-redux'

import HomeIcon from '@rsuite/icons/legacy/Home';
import UserIcon from '@rsuite/icons/legacy/User';
import AdminIcon from '@rsuite/icons/legacy/Lock';
import LogoutIcon from '@rsuite/icons/legacy/PowerOff';

function Navigation() {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const loggedin = useSelector((state) => state.auth.isLoggedIn);
    const { user } = useSelector((state) => state.auth);
    const admin = localStorage.getItem('management');

    function handleLogout() {
        const url = admin ? '/management/login' : '/login'
        dispatch(setLoggedOut());
        navigator(url);
    }

    return (
        <>
            {admin ?
                <Navbar className='navigationContainer'
                    appearance='default'>
                    <Nav>
                        {
                            loggedin ?
                                (
                                    <>
                                        <LinkContainer to='/management/'>
                                            <Nav.Item eventKey='2'
                                                icon={<UserIcon />}>
                                                Users
                                            </Nav.Item>
                                        </LinkContainer>
                                        {user.admin ?
                                            <LinkContainer to='/management/admin'>
                                                <Nav.Item eventKey='3'
                                                    icon={<AdminIcon />}>
                                                    Admin
                                                </Nav.Item>
                                            </LinkContainer> :
                                            <></>
                                        }
                                    </>
                                ) :
                                <></>
                        }
                    </Nav>
                    <Nav pullRight>
                        {
                            loggedin ?
                                <>
                                    <Nav.Item>
                                        Hi, {user.username}!
                                    </Nav.Item>
                                    <Nav.Item eventKey='2'
                                        icon={<LogoutIcon color='red' />}
                                        onClick={handleLogout}>
                                        Logout
                                    </Nav.Item>
                                </>
                                :
                                <>
                                    <LinkContainer to='/management/admin'>
                                        <Nav.Item eventKey='3'
                                            icon={<UserIcon />}>
                                            User login
                                        </Nav.Item>
                                    </LinkContainer>
                                    <LinkContainer to='/management/admin'>
                                        <Nav.Item eventKey='3'
                                            icon={<AdminIcon />}>
                                            Management login
                                        </Nav.Item>
                                    </LinkContainer>
                                </>
                        }
                    </Nav>
                </Navbar> :
                <Navbar appearance='default'>
                    <Nav>
                        {loggedin ?

                            <>
                                <LinkContainer to='/'>
                                    <Nav.Item eventKey='1'
                                        icon={<HomeIcon />}>
                                        Profile
                                    </Nav.Item>
                                </LinkContainer>
                            </> : <></>
                        }
                    </Nav>
                    <Nav pullRight>
                        {
                            loggedin ?
                                <>
                                    <Nav.Item>
                                        Hi, {user.name}!
                                    </Nav.Item>
                                    <Nav.Item eventKey='2'
                                        icon={<LogoutIcon color='red' />}
                                        onClick={handleLogout}>
                                        Logout
                                    </Nav.Item>
                                </>
                                :
                                <>
                                    <LinkContainer to='/login'>
                                        <Nav.Item eventKey='3'
                                            icon={<UserIcon />}>
                                            User login
                                        </Nav.Item>
                                    </LinkContainer>
                                    <LinkContainer to='/management/login'>
                                        <Nav.Item eventKey='3-1'
                                            icon={<AdminIcon />}>
                                            Management login
                                        </Nav.Item>
                                    </LinkContainer>
                                </>
                        }
                    </Nav>
                </Navbar>
            }
        </>
    );
}

export default Navigation;