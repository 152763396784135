const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validateEmailInput = (value) => {
    if (!value) {
        return 'This field is required.'
    } else if (!emailRegex.test(value)) {
        return 'Please enter a valid email.'
    } else {
        return null;
    }
}

export default validateEmailInput;