import { Button } from "rsuite";

function ToggleButton({ value, text, onChange }) {
    const handleClick = () => {
        if (value === true) {
            onChange(false);
        } else if (value === false) {
            onChange(null);
        } else {
            onChange(true);
        }
    };

    const appearance = value === true ? 'primary' : value === false ? 'primary' : 'default';
    const color = value === false ? 'red' : null;

    return (
        <Button appearance={appearance}
            color={color}
            onClick={handleClick}>
            {text}
        </Button>
    );
}

export default ToggleButton;