import { InputGroup, Input, MaskedInput, Row, Container, InputPicker, Divider, Tooltip, Whisper, Button } from 'rsuite';
import { useState } from 'react';
import { useMessageToaster } from '../Messages';
import { useNavigate } from 'react-router-dom';

import './Signup.css';
import validatePasswordInput from '../../functions/passwordCheck';
import validateEmailInput from '../../functions/emailCheck';

import PhoneIcon from '@rsuite/icons/PhoneFill';
import Password from '@rsuite/icons/legacy/Lock';
import PasswordView from '@rsuite/icons/legacy/Eye';
import PasswordNoView from '@rsuite/icons/legacy/EyeSlash';
import Email from '@rsuite/icons/EmailFill';
import InfoIcon from '@rsuite/icons/legacy/Info';
import sanitize from '../../functions/sanitizeInput';
import api from '../api';

// const {
//     showSuccessMessage,
//     showErrorMessage,
// } = useMessageToaster();
const style = {
    rowStyle: {
        display: 'flex',
        marginBottom: '10px'
    },
    dividerStyle: {
        width: 0,
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        marginLeft: '10px',
    },
    errorStyle: {
        borderColor: 'rgb(252, 103, 103)',
    }
}
const province = [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'North West',
    'Northern Cape',
    'Western Cape'
].map(item => ({
    label: item,
    value: item
}));

function Signup({ ...props }) {
    const navigator = useNavigate();
    const { standalone } = props;

    const {
        showSuccessMessage,
        showErrorMessage,
    } = useMessageToaster();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);

    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(null);

    const [lastName, setlastName] = useState('');
    const [lastNameError, setlastNameError] = useState(null);

    const [idNumber, setidNumber] = useState('');
    const [idNumberError, setidNumberError] = useState(null);

    const [cellNumber, setcellNumber] = useState('');
    const [cellNumberError, setcellNumberError] = useState(null);

    const [location, setLocation] = useState('');
    const [locationError, setLocationError] = useState(null);

    const [provinceVar, setProvince] = useState('');
    const [provinceError, setProvinceError] = useState(null);

    const [passwordValue, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setpasswordError] = useState(null);
    
    function handlEmailChange(value) {
        const email = sanitize(value);
        const emailCheck = validateEmailInput(email)
        setEmailError(emailCheck);
        setEmail(email);
    }

    function handleShowPassword() {
        setShowPassword(!showPassword);
    }
    function handlePasswordChange(value) {
        const password = sanitize(value);
        const passwordCheck = validatePasswordInput(password);
        setpasswordError(passwordCheck);
        setPassword(password);
    }

    function handleError() {
        setFirstNameError(!firstName);
        setlastNameError(!lastName);
        setEmailError(!email);
        setpasswordError(!passwordValue);
        setidNumberError(!idNumber);
        setcellNumberError(!cellNumber);
        setLocationError(!location);
        setProvinceError(!provinceVar);
    }

    function handleCancelClick() {
        setEmail('');
        setFirstName('');
        setlastName('');
        setidNumber('');
        setPassword('');
        setcellNumber('');
        setLocation('');
        setProvince('');
    }

    function handleSubmit() {
        if (!firstName || !lastName || !email || !passwordValue) {
            handleError();
            showErrorMessage('unable to add user.');
        } else {
            const userObject = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: passwordValue,
                id_number: idNumber,
                cell_number: cellNumber,
                location: location,
                province: provinceVar
            };
            console.log(userObject);

            fetch(api.baseURL + '/auth/createuser', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    user: userObject
                })
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                return response.json();
            }).then(responseData => {
                console.log(responseData);
                showSuccessMessage('User created');
                if (standalone) {
                    navigator('/login');
                } else {
                    const { createCallback } = props;
                    createCallback();
                }
            }).catch(error => {
                showErrorMessage(error.message);
                console.error(error);
            });
        }
    }

    return (
        <div className="signUpContainer shadow">
            <Container className="userDataContainer">
                <div className='titleContainer'>
                    <h3>Create an account</h3>
                    <p>Already have an account? <a href='/login'>Login here</a></p>
                    <Divider style={{
                        width: '60%'
                    }}>OR</Divider>
                </div>
                <Row style={style.rowStyle}>
                    <Input placeholder="Name"
                        value={firstName}
                        onChange={(value) => { setFirstName(sanitize(value)) }}
                        style={firstNameError ? style.errorStyle : null}
                    />
                    <Divider vertical style={style.dividerStyle} />
                    <Input placeholder="Surname"
                        value={lastName}
                        style={lastNameError ? style.errorStyle : null}
                        onChange={(value) => { setlastName(sanitize(value)) }} />
                </Row>
                <Row style={style.rowStyle}>
                    <InputGroup inside
                        style={emailError ? style.errorStyle : null}>
                        <InputGroup.Addon>
                            {emailError ?
                                <Whisper placement='top'
                                    speaker={<Tooltip>{emailError}</Tooltip>}>
                                    <InfoIcon color='red' />
                                </Whisper> :
                                <Email />
                            }
                        </InputGroup.Addon>
                        <Input placeholder='Email'
                            value={email}
                            onChange={handlEmailChange} />
                    </InputGroup>
                </Row>
                <Row style={style.rowStyle}>
                    <InputGroup inside
                        style={passwordError ? style.errorStyle : null}>
                        <InputGroup.Addon>
                            {passwordError ?
                                <Whisper placement='top'
                                    speaker={<Tooltip>{passwordError}</Tooltip>}>
                                    <InfoIcon color='red' />
                                </Whisper> :
                                <Password />
                            }
                        </InputGroup.Addon>
                        <Input placeholder='Password'
                            value={passwordValue}
                            style={passwordError ? style.errorStyle : null}
                            type={showPassword ? 'text' : 'password'}
                            onChange={handlePasswordChange}
                        />
                        <InputGroup.Button onClick={handleShowPassword}>
                            {showPassword ?
                                <PasswordNoView /> :
                                <PasswordView />
                            }
                        </InputGroup.Button>
                    </InputGroup>
                </Row>
                <Row style={style.rowStyle}>
                    <MaskedInput placeholder="ID Number"
                        style={idNumberError ? style.errorStyle : null}
                        value={idNumber}
                        guide={false}
                        onChange={setidNumber}
                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]} />
                    <Divider vertical style={style.dividerStyle} />
                    <InputGroup style={cellNumberError ? style.errorStyle : null}>
                        <InputGroup.Addon>
                            {cellNumberError ?
                                <InfoIcon color='red' /> :
                                <PhoneIcon />
                            }
                        </InputGroup.Addon>
                        <MaskedInput placeholder='Cell No.'
                            style={cellNumberError ? style.errorStyle : null}
                            value={cellNumber}
                            guide={false}
                            onChange={setcellNumber}
                            mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]} />
                        {/* <Input placeholder="Cell No."
                            style={cellNumberError ? style.errorStyle : null}
                            value={cellNumber}
                            onChange={setcellNumber} /> */}
                    </InputGroup>
                </Row>
                <Row style={style.rowStyle}>
                    <Input placeholder="Town/City"
                        style={locationError ? style.errorStyle : null}
                        value={location}
                        onChange={(value) => { setLocation(sanitize(value)) }} />
                    <Divider vertical
                        style={style.dividerStyle} />

                    <InputPicker placeholder="Province"
                        style={provinceError ? style.errorStyle : null}
                        data={province}
                        value={provinceVar}
                        placement='auto'
                        onChange={setProvince}
                        cleanable={false} />
                </Row>
                <Row style={style.rowStyle}>
                    <div className='signupButton'>
                        <Button appearance='ghost'
                            color='red'
                            onClick={handleCancelClick}>Cancel</Button>
                        <Button appearance='primary'
                            onClick={handleSubmit}>Signup</Button>
                    </div>
                </Row>
            </Container>
        </div>
    );
}

export default Signup;