import { Divider, Input, InputGroup, Button, Whisper, Tooltip, MaskedInput } from 'rsuite';
import './ForgotPassword.css';
import Email from '@rsuite/icons/EmailFill';
import Lock from '@rsuite/icons/legacy/Lock';
import sanitize from '../functions/sanitizeInput';
import { useState } from 'react';
import { useMessageToaster } from './Messages';

import InfoIcon from '@rsuite/icons/legacy/Info';
import validateEmailInput from '../functions/emailCheck';
import api from './api';
import validatePasswordInput from '../functions/passwordCheck';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
    const navigator = useNavigate();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const [sendingEmail, setSendingEmail] = useState(false);
    const [sentEmailValue, setSentEmailValue] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const [passwordValue, setPassword] = useState('');
    // const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setpasswordError] = useState(null);
    const [confirmPasswordValue, setConfirmPassword] = useState('');
    // const [confirmShowPassword, setConfirmShowPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);

    const [resetCode, setResetCode] = useState(null);
    const [resetCodeError, setResetCodeError] = useState(false);

    const {
        showSuccessMessage,
        showErrorMessage,
    } = useMessageToaster();

    const style = {
        rowStyle: {
            marginTop: '25px',
            marginBottom: '20px'
        },
        errorStyle: {
            borderColor: 'rgb(252, 103, 103)',
            // marginTop: '25px',
            marginBottom: '10px'
        }
    }

    function handlEmailChange(value) {
        const email = sanitize(value);
        const emailCheck = validateEmailInput(email)
        setEmailError(emailCheck);
        setEmail(email);
    }

    function handleSubmit() {
        setSendingEmail(true);
        fetch(api.baseURL + '/auth/email-forgot', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email
            })
        }).then(response => {
            console.log(response);
            if (!response.ok) {
                if (response.status === 400) {
                    throw new Error('No account associated with that email');
                }
                throw new Error('Network response was not ok.');
            }
            return response.json();
        }).then(responseData => {
            setSentEmailValue(email);
            setEmailSent(true);
            showSuccessMessage('Email sent');
            console.log(responseData);
            setSendingEmail(false);
        }).catch(error => {
            showErrorMessage(error.message);
            console.error(error);
        });
    }

    function handlePasswordChange(value) {
        const password = sanitize(value);
        const passwordCheck = validatePasswordInput(password);
        setpasswordError(passwordCheck);
        setPassword(password);
    }

    function handleConfirmPasswordChange(value) {
        const confirmPassword = sanitize(value);
        const confirmPasswordCheck = validatePasswordInput(confirmPassword);

        if (passwordValue !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
        } else if (confirmPasswordCheck) {
            setConfirmPasswordError(confirmPasswordCheck);
        } else {
            setConfirmPasswordError(null);
        }
        setConfirmPassword(confirmPassword);
    }

    async function handleResetPassword() {
        setpasswordError(!passwordValue);
        setConfirmPasswordError(!confirmPasswordValue);
        setResetCodeError(!resetCode);

        if (passwordError || confirmPasswordError || resetCodeError) {
            showErrorMessage('Please fix all errors to continue');
            return;
        }

        const result = await fetch(api.baseURL + '/auth/user-password-reset', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: sentEmailValue,
                code: resetCode,
                password: passwordValue
            })
        });
        const response = result.json();
        if (!result.ok) {
            showErrorMessage(response.message);
            return;
        } else {
            showSuccessMessage('Password updated');

            if (response.admin) {
                navigator('/management/login');
                return;
            } else {
                navigator('/login');
                return;
            }
        }
    }

    return (
        <div className='forgotContainer'>
            {!emailSent ?
                <div className='inputContainer'>
                    <Divider style={{ width: '90%' }}>
                        <h5>
                            Forgot Password
                        </h5>
                    </Divider>

                    <div className='inputButtonContainer'>
                        <p>Please enter the email address associated with your account and we'll send you a code to reset your password</p>

                        <InputGroup inside
                            style={emailError ? style.errorStyle : style.rowStyle}>
                            <InputGroup.Addon>
                                {emailError ?
                                    <Whisper placement='top'
                                        speaker={<Tooltip>{emailError}</Tooltip>}>
                                        <InfoIcon color='red' />
                                    </Whisper> :
                                    <Email />
                                }
                            </InputGroup.Addon>
                            <Input placeholder='Email'
                                value={email}
                                onChange={handlEmailChange}
                                disabled={sendingEmail} />
                        </InputGroup>

                        <Button appearance='primary'
                            size='lg'
                            block
                            loading={sendingEmail}
                            style={{ textAlign: 'center' }}
                            onClick={handleSubmit}>
                            Continue
                        </Button>

                        <p>Don't have an account? <a href='/signup'>Sign up</a></p>
                    </div>
                </div> : <></>
            }

            {emailSent ?
                <div className='inputContainer'>
                    <Divider style={{ width: '90%' }}>
                        <h5>
                            Reset Password
                        </h5>
                    </Divider>

                    <div className='inputButtonContainer'>
                        <p>Please enter the code sent to your email address.</p>

                        <InputGroup style={passwordError ? style.errorStyle : { marginBottom: '10px' }}>
                            <InputGroup.Addon>
                                {passwordError ?
                                    <Whisper placement='top'
                                        speaker={<Tooltip>{passwordError}</Tooltip>}>
                                        <InfoIcon color='red' />
                                    </Whisper> :
                                    <Lock />
                                }
                            </InputGroup.Addon>
                            <Input placeholder='New password'
                                onChange={(value) => { handlePasswordChange(value) }} />
                        </InputGroup>

                        <InputGroup style={confirmPasswordError ? style.errorStyle : { marginBottom: '10px' }}>
                            <InputGroup.Addon>
                                {confirmPasswordError ?
                                    <Whisper placement='top'
                                        speaker={<Tooltip>{confirmPasswordError}</Tooltip>}>
                                        <InfoIcon color='red' />
                                    </Whisper> :
                                    <Lock />
                                }
                            </InputGroup.Addon>
                            <Input placeholder='Confirm password'
                                onChange={(value) => { handleConfirmPasswordChange(value) }} />
                        </InputGroup>

                        <MaskedInput placeholder="Reset code"
                            style={resetCodeError ?
                                {
                                    textAlign: 'center',
                                    marginBottom: '25px',
                                    borderColor: 'rgb(252, 103, 103)'
                                } : {
                                    textAlign: 'center',
                                    marginBottom: '25px'
                                }}
                            mask={[/\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/]}
                            onChange={setResetCode} />

                        <Button appearance='primary'
                            size='lg'
                            block
                            style={{ textAlign: 'center' }}
                            onClick={() => { handleResetPassword() }}>
                            Reset Password
                        </Button>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25px' }}>
                            <Button appearance='link' size='sm'
                                onClick={() => { setEmailSent(false) }}>Go back</Button>
                        </div>
                    </div>
                </div> : <></>
            }
        </div>
    );
}

export default ForgotPassword;