import { useState, useEffect } from "react";
import { Drawer, Button, Col, Divider, MaskedInput, Slider, Row, Container, DatePicker, Tooltip, Whisper, Toggle, InputPicker, InputGroup, Input, Loader, Panel } from "rsuite";

import DataObjects from "../DataObjects";
import { useMessageToaster } from '../Messages';
import moment from 'moment';

import PhoneIcon from '@rsuite/icons/PhoneFill';
import InfoIcon from '@rsuite/icons/InfoRound';
import EmailIcon from '@rsuite/icons/EmailFill';
import api from "../api";
const style = {
    rowStyle: {
        display: 'flex',
        marginBottom: '10px'
    },
    dividerStyle: {
        width: 0,
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        marginLeft: '10px',
    }
};
const marks = {
    0: 'Very bad',
    25: 'Bad',
    50: 'Middle',
    75: 'Good',
    100: 'Very good'
};

function UserDrawerPage({ ...props }) {
    const { id, open, onClose, update, readonly } = props;
    const [userInfo, setUser] = useState(DataObjects.DEFAUL_USER_INFO);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');

    const {
        showInfoMessage,
        showErrorMessage,
        showSuccessMessage
    } = useMessageToaster();

    const handleInputChange = (fieldName, newValue) => {
        setUser((prevUserInfo) => ({
            ...prevUserInfo,
            [fieldName]: newValue
        }));
    };

    const handleSave = () => {
        userInfo.last_updated = moment(Date.now()).format('DD/MM/YYYY');

        fetch(api.baseURL + '/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                user: userInfo
            })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            return response.json();
        }).then(responseData => {
            if (responseData.message === 'no new records found') {
                showInfoMessage(responseData.message);
            } else {
                showSuccessMessage(responseData.message);
                update();
            }
        }).catch(error => {
            showErrorMessage(error.message);
            console.error(error);
        });
    };

    const handleNewUser = () => {
        userInfo.last_updated = moment(Date.now()).format('DD/MM/YYYY');

        fetch(api.baseURL + '/transfer-new-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                user: userInfo
            })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            return response.json();
        }).then(responseData => {
            if (responseData.message === 'no new records found') {
                showInfoMessage(responseData.message);
            } else {
                showSuccessMessage(responseData.message);
                const userdata = userInfo;
                userdata.is_new = false;
                setUser(userdata);
                update();
            }
        }).catch(error => {
            showErrorMessage(error.message);
            console.error(error);
        });
    };

    const handleDrawerClose = () => {
        onClose();
    };

    const handleContacted = () => {
        userInfo.contact_me = false;
        userInfo.contact_reason = '';

        handleSave();
    }

    useEffect(() => {
        if (open) {
            setUser(DataObjects.DEFAUL_USER_INFO);
            const fetchData = () => {
                fetch(api.baseURL + '/userprofile', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ id: id })
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setUser(data);
                        setLoading(false);
                    })
                    .catch((error) => console.log(error));
            };
            fetchData();
            const interval = setInterval(fetchData, 500000);
            return () => clearInterval(interval);
        }
        }, [id, open]);

    return (
        <Drawer size='lg'
            placement='right'
            open={open}
            onClose={handleDrawerClose}>
            <Drawer.Header>
                <Drawer.Title>
                    User details
                </Drawer.Title>
                <Drawer.Actions>
                    <Button appearance='ghost'
                        color='red'
                        onClick={handleDrawerClose}>
                        Cancel
                    </Button>
                    {userInfo?.is_new === true ?
                        <Button onClick={handleNewUser}
                            appearance="primary"
                            color='green'
                            disabled={readonly}>
                            Create
                        </Button> :
                        <Button onClick={handleSave}
                            appearance="primary"
                            disabled={readonly}>
                            Save
                        </Button>
                    }
                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body style={{ padding: 0, backgroundColor: 'transparent' }}>
                {userInfo?.contact_me ?
                    <Panel header={
                        <h5>Contact</h5>
                    }
                        bordered
                        collapsible
                        style={{
                            margin: '20px',
                            borderColor: '#FAA0A0'
                        }}>
                        <h5>Reason:</h5>
                        <p>{userInfo?.contact_reason}</p>
                        <h5>Prefered contact:</h5>
                        <p>{userInfo?.contact_method}</p>
                        <div className="d-flex">
                            <h5>Details:</h5>
                            <div className="d-flex flex-column">
                                <InputGroup style={{
                                    margin: '10px',
                                    marginTop: '0px'
                                }}>
                                    <InputGroup.Addon>
                                        <EmailIcon />
                                    </InputGroup.Addon>
                                    <Input value={userInfo?.email}
                                        readOnly />
                                </InputGroup>
                                <InputGroup style={{
                                    margin: '10px',
                                    marginTop: '0px'
                                }}>
                                    <InputGroup.Addon>
                                        <PhoneIcon />
                                    </InputGroup.Addon>
                                    <Input value={userInfo?.phone_number}
                                        readOnly />
                                </InputGroup>
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button appearance="primary"
                                onClick={() => { handleContacted() }}>Contacted</Button>
                        </div>
                    </Panel> : <></>
                }

                <div className='drawerBodyContainer'>
                    {loading ?
                        <Loader vertical
                            backdrop
                            content='loading...'
                        /> : <></>
                    }

                    <Col xs={12}>
                        <div className='bodyColLeft'>
                            <Container className="profileUserContainer shadow-sm">
                                <Container className="profileUserData">
                                    <Divider>User Details</Divider>
                                    <Row style={style.rowStyle}>
                                        <InputPicker
                                            placeholder='Title'
                                            cleanable={false}
                                            style={{ width: '120px' }}
                                            value={userInfo?.title ?? ''}
                                            name='title'
                                            data={DataObjects.title}
                                            onChange={(value) => handleInputChange('title', value)}
                                        />
                                        <Divider vertical
                                            style={style.dividerStyle} />
                                        <InputGroup style={{
                                            width: '180px'
                                        }}>
                                            <InputGroup.Addon>
                                                <PhoneIcon />
                                            </InputGroup.Addon>
                                            <MaskedInput placeholder='Cell No.'
                                                guide={false}
                                                value={userInfo?.phone_number ?? ''}
                                                onChange={(value) => handleInputChange('phone_number', value)}
                                                mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]} />
                                        </InputGroup>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <Input placeholder="Name"
                                            value={userInfo?.name ?? ''}
                                            onChange={(value) => handleInputChange('name', value)}
                                            style={{ width: '150px' }} />
                                        <Divider vertical style={style.dividerStyle} />
                                        <Input placeholder="Surname"
                                            value={userInfo?.surname ?? ''}
                                            onChange={(value) => handleInputChange('surname', value)}
                                        />
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <InputGroup>
                                            <InputGroup.Addon><EmailIcon /></InputGroup.Addon>
                                            <Input placeholder="Email"
                                                value={userInfo?.email ?? ''}
                                                onChange={(value) => handleInputChange('email', value)}
                                            />
                                        </InputGroup>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <Input placeholder="Town/City"
                                            value={userInfo?.location ?? ''}
                                            onChange={(value) => handleInputChange('location', value)}
                                        />
                                        <Divider vertical
                                            style={style.dividerStyle} />
                                        <InputPicker placeholder="Province"
                                            data={DataObjects.province}
                                            cleanable={false}
                                            style={{ width: '280px' }}
                                            value={userInfo?.province ?? ''}
                                            onChange={(value) => handleInputChange('province', value)}
                                        />
                                    </Row>
                                </Container>
                            </Container>

                            <Container className="profileUserContainer shadow-sm">
                                <Container className="profileAdditionalContainer">
                                    <Divider>Additional Details</Divider>
                                    <Row style={style.rowStyle}>
                                        <div className="profileMsQuestion">
                                            <p>Do you have Ms? </p>
                                            <div>
                                                <Toggle checkedChildren='Yes'
                                                    unCheckedChildren='No'
                                                    checked={userInfo?.has_ms ?? false}
                                                    onChange={(value) => handleInputChange('has_ms', value)}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <DatePicker format="yyyy-MM"
                                            placeholder='Year diagnosed'
                                            ranges={[]}
                                            disabled={!userInfo?.has_ms}
                                            value={userInfo?.year_diagnosed ? new Date(userInfo?.year_diagnosed) : ''}
                                            onChange={(value) => handleInputChange('year_diagnosed', value)}
                                        />
                                        <Divider vertical
                                            style={style.dividerStyle} />
                                        <InputPicker placeholder='Interest in MS'
                                            data={DataObjects.reason}
                                            disabled={userInfo?.has_ms}
                                            value={userInfo?.reason}
                                            onChange={(value) => handleInputChange('reason', value)}
                                            style={{ flexGrow: 1 }} />
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <MaskedInput placeholder="ID Number"
                                            value={userInfo?.id_number}
                                            onChange={(value) => handleInputChange('id_number', value)}
                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]} />
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex">
                                            <DatePicker placeholder='Date of birth'
                                                value={userInfo?.date_of_birth ? new Date(userInfo?.date_of_birth) : ''}
                                                onChange={(value) => handleInputChange('date_of_birth', value)}
                                                placement='auto'
                                            />
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker placeholder='Country of birth'
                                                data={DataObjects.countryList}
                                                searchable
                                                value={userInfo?.counrty_of_birth}
                                                onChange={(value) => handleInputChange('country_of_birth', value)}
                                                style={{ flexGrow: 1 }} />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <InputGroup>
                                            <InputGroup.Addon>
                                                <Whisper placement='top'
                                                    speaker={
                                                        <Tooltip>We apologise for asking this but it is important statitcally due to the rapidly changing demographics of MS in South Africa</Tooltip>
                                                    }>
                                                    <InfoIcon />
                                                </Whisper>
                                            </InputGroup.Addon>
                                            <InputPicker placeholder='Ethnicity'
                                                data={DataObjects.race}
                                                value={userInfo?.ethnicity}
                                                onChange={(value) => handleInputChange('ethnicity', value)}
                                                searchable
                                                style={{ flexGrow: 1 }} />
                                        </InputGroup>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>History of MS:</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker placeholder='No'
                                                data={DataObjects.msHistory}
                                                cleanable={false}
                                                value={userInfo?.history_ms}
                                                onChange={(value) => handleInputChange('history_ms', value)}
                                                style={{ flex: 1 }} />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>I live with:</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker
                                                data={DataObjects.liveArray}
                                                value={userInfo?.live_with}
                                                onChange={(value) => handleInputChange('live_with', value)}
                                                style={{ flex: 2 }} />
                                        </div>
                                    </Row>

                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>Mobility:</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker
                                                value={userInfo?.mobility}
                                                data={DataObjects.mobilityList}
                                                placement='auto'
                                                onChange={(value) => handleInputChange('mobility', value)}
                                                style={{ flex: 2 }} />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>Driving:</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker
                                                data={DataObjects.drivingList}
                                                value={userInfo?.driving}
                                                placement='auto'
                                                onChange={(value) => handleInputChange('driving', value)}
                                            />
                                        </div>
                                    </Row>
                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>Incontinence issues:</p>
                                        <div className="d-flex align-items-center">
                                            <InputPicker
                                                data={DataObjects.IncontinenceList}
                                                style={{ width: '100%' }}
                                                placement='auto'
                                                value={userInfo?.incontinence_issues}
                                                onChange={(value) => handleInputChange('incontinence_issues', value)}
                                            />
                                        </div>
                                    </Row>
                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>What type of MS do you have?</p>
                                        <div className="d-flex align-items-center">
                                            <InputPicker
                                                data={DataObjects.msList}
                                                style={{ width: '100%' }}
                                                value={userInfo?.type_ms}
                                                placement='auto'
                                                onChange={(value) => handleInputChange('type_ms', value)}
                                            />
                                        </div>
                                    </Row>
                                </Container>
                            </Container>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className='bodyColRight'>
                            <Container className="profileUserContainer shadow-sm">

                                <Container style={{ padding: '10px' }}>
                                    <Divider style={{ width: '100%' }}>Medical aid</Divider>

                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>Are you on a medical aid plan?</p>
                                        <div className="d-flex align-items-center">
                                            <InputPicker
                                                data={DataObjects.medicalAidList}
                                                searchable
                                                style={{ width: '100%' }}
                                                value={userInfo?.on_medical_plan}
                                                onChange={(value) => handleInputChange('on_medical_plan', value)}

                                            // onChange={setMedicalAid} 
                                            />
                                        </div>
                                    </Row>
                                    <Row style={{ ...style.rowStyle, display: 'flex', alignItems: 'center' }}>
                                        <p style={{ marginBottom: '5px', width: '80%' }}>Are you able to access cronic medication with your medical aid?</p>
                                        <Toggle checkedChildren='Yes'
                                            unCheckedChildren='No'
                                            checked={userInfo?.access_cronic_medication}
                                            onChange={(value) => handleInputChange('access_cronic_medication', value)}

                                        // onClick={() => { setCronicAccess(!cronicAccess) }} 
                                        />
                                    </Row>
                                </Container>
                            </Container>

                            <Container className="profileUserContainer shadow-sm">
                                <Container style={{ padding: '10px' }}>
                                    <Divider style={{ width: '100%' }}>Medication</Divider>
                                    <Row style={{
                                        ...style.rowStyle,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <p style={{
                                            marginBottom: '5px',
                                            width: '80%'
                                        }}>
                                            Do you receive medication from a goverment clinic or hospital?
                                        </p>
                                        <Toggle checkedChildren='Yes'
                                            unCheckedChildren='No'
                                            checked={userInfo?.goverment_clinic_hospital}
                                            onChange={(value) => handleInputChange('goverment_clinic_hospital', value)}

                                        // onClick={() => { setMedicationHospital(!medicationHospital) }} 
                                        />
                                    </Row>

                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>Which facility do you draw your medication from?</p>
                                        <div className="d-flex align-items-center">
                                            <Input style={{ width: '100%' }}
                                                value={userInfo?.facility_recieved}
                                                onChange={(value) => handleInputChange('facility_recieved', value)}

                                                // onChange={setMedicationFacility}
                                                disabled={!userInfo?.goverment_clinic_hospital}
                                            />
                                        </div>
                                    </Row>

                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>
                                                Are you on a DMT?
                                            </p>
                                            <Whisper placement='top'
                                                speaker={
                                                    <Tooltip>Disease Modifying Therapy</Tooltip>
                                                }>
                                                <InfoIcon />
                                            </Whisper>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker cleanable={false}
                                                style={{ flex: 1 }}
                                                value={userInfo?.on_dmt}
                                                onChange={(value) => handleInputChange('on_dmt', value)}

                                                // onChange={setDmtUser}
                                                data={DataObjects.dmtList}
                                            />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>Which year did you start with DMT's?</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <DatePicker format="yyyy-MM"
                                                placement="auto"
                                                value={userInfo?.dmtStart ? new Date(userInfo?.dmtStart) : ''}
                                            />
                                        </div>
                                    </Row>
                                    {/* <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>Do you take other medication for MS?</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker
                                                // data={[]}
                                                // value={userInfo?.other_medication}
                                                onChange={(value) => handleInputChange('other_medication', value)}
                                            />
                                        </div>
                                    </Row> */}
                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>How well is your current medication working for you?</p>
                                        <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}>
                                            <Slider min={0} max={100}
                                                step={25}
                                                graduated
                                                marks={marks}
                                                value={userInfo?.medication_score}
                                            />
                                        </div>
                                    </Row>
                                </Container>
                            </Container>
                            <Container className="profileUserContainer shadow-sm">
                                <Container className="profilePreferenceContainer">
                                    <Divider>Preferences</Divider>
                                    <Row>
                                        <div className="profileMonthlyQuestion">
                                            <p style={{ width: '80%' }}>Would you like to recieve the monthly newsletter?</p>
                                            <Toggle checkedChildren='Yes'
                                                unCheckedChildren='No'
                                                checked={userInfo?.receive_newsletter}
                                                onChange={(value) => handleInputChange('receive_newsletter', value)}
                                            />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100">
                                            <p style={{ flex: 1, margin: 0 }}>Prefered method of contact?</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker
                                                placement="auto"
                                                data={DataObjects.contactOptions}
                                                cleanable={false}
                                                value={userInfo?.contact_method}
                                                onChange={(value) => handleInputChange('contact_method', value)}
                                            />
                                        </div>
                                    </Row>
                                </Container>
                            </Container>
                            <Container className="profileUserContainer shadow-sm">
                                <Container className="profilePreferenceContainer">
                                    <Divider>Other</Divider>
                                    <Row style={{ paddingBotton: '20px', marginBottom: '20px' }}>
                                        <div className="d-flex align-items-center w-80 justify-content-between">
                                            <p style={{ margin: 0, marginRight: '20px', }}>Last updated</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <DatePicker format="dd-MM-yyyy"
                                                placement="auto"
                                                readOnly
                                                style={{ width: '200px' }}
                                                value={userInfo?.last_updated ? moment(userInfo?.last_updated, 'DD/MM/YYYY').toDate() : ''}
                                            />
                                        </div>
                                    </Row>
                                </Container>
                            </Container>
                        </div>
                    </Col>
                </div>
            </Drawer.Body>
        </Drawer>
    );
}

export default UserDrawerPage;

