import './Admin.css'
import { Input, InputGroup, TagPicker, Checkbox, Divider, Table, Button, IconButton, Modal, Whisper, Tooltip } from "rsuite";
import AddAdminUser from './AddAdminUser';

import SearchIcon from '@rsuite/icons/legacy/Search';
import DeleteIcon from '@rsuite/icons/legacy/Trash';
import Password from '@rsuite/icons/legacy/Lock';
import PasswordView from '@rsuite/icons/legacy/Eye';
import PasswordNoView from '@rsuite/icons/legacy/EyeSlash';
import InfoIcon from '@rsuite/icons/legacy/Info';
import RefreshIcon from '@rsuite/icons/legacy/Refresh';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMessageToaster } from './Messages';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import validatePasswordInput from '../functions/passwordCheck';
import sanitize from '../functions/sanitizeInput';
import api from './api';

function Admin() {
    const {
        showErrorMessage,
        showSuccessMessage
    } = useMessageToaster();
    const navigator = useNavigate();
    const { user } = useSelector((state) => state.auth);

    if (!user.admin) {
        navigator('/');
    }
    const [passwordValue, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setpasswordError] = useState(null);
    const [confirmPasswordValue, setConfirmPassword] = useState('');
    const [confirmShowPassword, setConfirmShowPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);

    const [data, setData] = React.useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filterData, setFilterData] = useState(data);
    const token = localStorage.getItem('token');

    function handleShowPassword() {
        setShowPassword(!showPassword);
    }
    function handlePasswordChange(value) {
        const passwordCheck = validatePasswordInput(value);
        setpasswordError(passwordCheck);
        setPassword(value);
    }

    function handleConfirmShowPassword() {
        setConfirmShowPassword(!confirmShowPassword);
    }
    function handleConfirmPasswordChange(value) {
        setConfirmPasswordError(value !== passwordValue);
        setConfirmPassword(value);
    }

    function handleAdminChange(rowData) {
        fetch(api.baseURL + '/auth/admin-status-change', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: rowData._id,
                admin: !rowData.admin
            })
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok.');
            }
        }).then((data) => {
            tableRefresh();
        }).catch((error) => console.log(error));
    }

    const handlePermissionsFilterChange = (value) => {
        setSelectedPermissions(value);
    };

    function handlePermissionsChange(rowData, value) {
        fetch(api.baseURL + '/auth/admin-perm-change', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: rowData._id,
                permissions: value
            })
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok.');
            }
        }).then((data) => {
            tableRefresh();
        }).catch((error) => console.log(error));
    }

    function handleReset(rowData) {
        handleOpenModal(rowData, 'reset');
    }

    function handleDeleteUser(rowData) {
        handleOpenModal(rowData, 'delete');
    }

    const style = {
        rowStyle: {
            display: 'flex',
            marginBottom: '10px'
        },
        dividerStyle: {
            width: 0,
            padding: 0,
            margin: 0,
            backgroundColor: 'transparent',
            marginLeft: '10px',
        },
        errorStyle: {
            borderColor: 'rgb(252, 103, 103)',
            marginBottom: '10px'
        }
    }

    const handleSearchChange = (value) => {
        setSearchValue(sanitize(value));
    };

    function handleDeleteClick() {
        fetch(api.baseURL + '/auth/remove-admin', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user: modalData?.data,
            })
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok.');
            }
        }).then((data) => {
            showSuccessMessage(data.message);
            tableRefresh();
            handleModalClose();
        }).catch((error) => console.log(error));
    }

    function handleResetClick() {
        setpasswordError(!passwordValue);
        setConfirmPasswordError(!confirmPasswordValue);

        if (passwordError || confirmPasswordError) {
            showErrorMessage('Please fix all errors');
        } else {
            fetch(api.baseURL + '/auth/admin-password-reset', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: modalData?.data,
                    password: passwordValue
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            }).then((data) => {
                showSuccessMessage(data.message);
            }).catch((error) => console.log(error));
        }
    }

    const { Column, HeaderCell, Cell } = Table;
    const permissionData = [
        {
            label: 'Read',
            value: 'Read'
        },
        {
            label: 'Create',
            value: 'Create'
        },
        {
            label: 'Export',
            value: 'Export'
        },
        {
            label: 'Update user',
            value: 'Update user'
        }
    ];

    const rowClassName = (rowData) => {
        if (!rowData) {
            return '';
        }
        if (rowData.email === user.email) {
            return 'currentUser';
        }
        return '';
    };

    useEffect(() => {
        tableRefresh();
    }, []);

    function tableRefresh() {
        setTableLoading(true);

        fetch(api.baseURL + '/adminusers', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((data) => {
                setData(data);
                setFilterData(data);
                setTableLoading(false);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        let filteredData = data.filter((item) => {
            if (isAdmin && !item.admin) {
                return false;
            }
            if (
                selectedPermissions.length > 0 &&
                !selectedPermissions.every((p) => item.permissions.includes(p))
            ) {
                return false;
            }
            if (
                searchValue &&
                !(
                    item.email.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.username.toLowerCase().includes(searchValue.toLowerCase())
                )
            ) {
                return false;
            }
            return true;
        });
        setFilterData(filteredData);
    }, [isAdmin, selectedPermissions, searchValue, data]);


    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    function handleOpenModal(rowData, task) {
        const title = task === 'reset' ? 'Reset password' : 'Delete user';
        const modalObject = {
            data: rowData,
            title: title,
            task: task,
        }
        setModalData(modalObject);
        setModalOpen(true);
    }

    function handleModalClose() {
        setModalOpen(false);
    }

    return (
        <div className='h-100 w-100'>
            <Modal open={modalOpen}
                onClose={handleModalClose}
                size='xs'>
                <Modal.Header>
                    {modalData?.title}
                </Modal.Header>
                <Modal.Body>
                    {modalData?.task === 'reset' ?
                        <div style={{
                            width: '250px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            paddingTop: '20px',
                            paddingBottom: '20px'
                        }}>
                            <InputGroup inside
                                style={passwordError ? style.errorStyle : style.rowStyle}>
                                <InputGroup.Addon>
                                    {passwordError ?
                                        <Whisper placement='top'
                                            speaker={<Tooltip>Please enter a valid password \n 8 Char/ Capital letter, Special char.</Tooltip>}>
                                            <InfoIcon color='red' />
                                        </Whisper> :
                                        <Password />
                                    }
                                </InputGroup.Addon>
                                <Input placeholder='Password'
                                    value={passwordValue}
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={handlePasswordChange}
                                />
                                <InputGroup.Button onClick={handleShowPassword}>
                                    {showPassword ?
                                        <PasswordNoView /> :
                                        <PasswordView />
                                    }
                                </InputGroup.Button>
                            </InputGroup>

                            <InputGroup inside
                                style={confirmPasswordError ? style.errorStyle : style.rowStyle}>
                                <InputGroup.Addon>
                                    {confirmPasswordError ?
                                        <Whisper placement='top'
                                            speaker={<Tooltip>Passwords do not match.</Tooltip>}>
                                            <InfoIcon color='red' />
                                        </Whisper> :
                                        <Password />
                                    }
                                </InputGroup.Addon>
                                <Input placeholder='Confirm password'
                                    value={confirmPasswordValue}
                                    type={confirmShowPassword ? 'text' : 'password'}
                                    onChange={handleConfirmPasswordChange}
                                />
                                <InputGroup.Button onClick={handleConfirmShowPassword}>
                                    {confirmShowPassword ?
                                        <PasswordNoView /> :
                                        <PasswordView />
                                    }
                                </InputGroup.Button>
                            </InputGroup>
                        </div> :
                        <p>Are you sure you want to delete user:
                            <span className='highlight'>
                                {modalData?.data?.username}
                            </span>
                        </p>
                    }
                </Modal.Body>

                <Modal.Footer>
                    {modalData?.task === 'reset' ?
                        <div>
                            <Button onClick={handleModalClose} appearance="subtle">
                                Cancel
                            </Button>
                            <Button onClick={handleResetClick} appearance="primary">
                                Reset
                            </Button>
                        </div> :
                        <div>
                            <Button onClick={handleModalClose} appearance="subtle">
                                Cancel
                            </Button>
                            <Button onClick={handleDeleteClick} appearance="ghost"
                                color='red'>
                                Delete
                            </Button>
                        </div>
                    }
                </Modal.Footer>
            </Modal>

            <div className='filterPosition shadow-sm'>
                <div className='filterContainer'>
                    <h5>Filter</h5>
                    <div className='filterOptions'>
                        <p>{filterData.length} results</p>
                        <Divider vertical />
                        <Checkbox checked={isAdmin}
                            onChange={() => { setIsAdmin(!isAdmin) }}>
                            Admin
                        </Checkbox>
                        <Divider vertical />
                        <TagPicker data={permissionData}
                            value={selectedPermissions}
                            onChange={handlePermissionsFilterChange}
                            preventOverflow={false}
                            style={{
                                width: '300px',
                            }} />
                        <Divider vertical />
                        <InputGroup
                            style={{
                                width: '200px',
                            }}>
                            <Input placeholder='Search'
                                value={searchValue}
                                onChange={handleSearchChange} />
                            <InputGroup.Button>
                                <SearchIcon />
                            </InputGroup.Button>
                        </InputGroup>
                        <IconButton appearance='primary'
                            style={{ marginLeft: '10px' }}
                            icon={<RefreshIcon />}
                            onClick={() => { tableRefresh() }} />
                    </div>
                </div>
            </div>
            <div className='adminContainer'>
                <div className='leftContainer shadow-sm'>
                    {data !== [] ? (
                        <div className='h-100 w-100'>
                            <Table data={filterData}
                                padding={10}
                                autoHeight
                                loading={tableLoading}
                                style={{ maxHeight: '100%' }}
                                rowClassName={rowClassName}>
                                <Column width={25}>
                                    <HeaderCell></HeaderCell>
                                    <Cell></Cell>
                                </Column>
                                <Column align='left'
                                    flexGrow={1}
                                    verticalAlign='middle'>
                                    <HeaderCell>Username</HeaderCell>
                                    <Cell dataKey="username" style={{
                                        padding: 0
                                    }} />
                                </Column>

                                <Column flexGrow={1}
                                    align='left'
                                    verticalAlign='middle'>
                                    <HeaderCell>Email</HeaderCell>
                                    <Cell dataKey="email" style={{
                                        padding: 0
                                    }} />
                                </Column>

                                <Column width={100}
                                    align='center'
                                    verticalAlign='middle'>
                                    <HeaderCell>Reset Password</HeaderCell>
                                    <Cell style={{
                                        padding: 0
                                    }}>
                                        {rowData => (
                                            <Button appearance="ghost"
                                                color='red'
                                                size='xs'
                                                onClick={() => { handleReset(rowData) }}>
                                                Reset
                                            </Button>
                                        )}
                                    </Cell>
                                </Column>

                                <Column width={80}
                                    align='center'
                                    verticalAlign='middle'>
                                    <HeaderCell>Admin</HeaderCell>
                                    <Cell style={{
                                        padding: 0
                                    }}>
                                        {rowData => (
                                            <Checkbox checked={rowData.admin}
                                                onChange={() => { handleAdminChange(rowData) }} />
                                        )}
                                    </Cell>
                                </Column>

                                <Column width={350}
                                    align='left'
                                    verticalAlign='middle'>
                                    <HeaderCell>Permissions</HeaderCell>
                                    <Cell style={{
                                        padding: 0,
                                    }}>
                                        {rowData => (
                                            <TagPicker
                                                data={permissionData}
                                                value={rowData.permissions}
                                                cleanable={false}
                                                preventOverflow
                                                onChange={(value) => { handlePermissionsChange(rowData, value) }}
                                                style={{
                                                    maxWidth: '350px',
                                                    padding: 0,
                                                }}
                                            />
                                        )}
                                    </Cell>
                                </Column>
                                <Column width={60}
                                    align='center'
                                    verticalAlign='middle'>
                                    <HeaderCell>Delete</HeaderCell>
                                    <Cell style={{
                                        padding: 0
                                    }}>
                                        {rowData => (
                                            <IconButton appearance="link"
                                                color='red'
                                                size='xs'
                                                icon={<DeleteIcon />}
                                                style={{ border: '0px' }}
                                                onClick={() => { handleDeleteUser(rowData) }}>
                                            </IconButton>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                        </div>
                    ) : (
                        <div className='emptyState text-secondary'>
                            <h5>Added users will appear here.</h5>
                        </div>
                    )}
                </div>
                <div className='rightContainer'>
                    {/* <DatabaseInfo /> */}
                    <AddAdminUser handleRefresh={tableRefresh} />
                </div>
            </div>
        </div>
    );
}

export default Admin;