import { Message, useToaster } from 'rsuite';

export function useMessageToaster() {
    const toaster = useToaster();

    function showSuccessMessage(message) {
        toaster.push(
            (<Message showIcon type='success'
                closable>
                Success: {message}!
            </Message>), {
            placement: 'topCenter',
            duration: 6000
        });
    }

    function showInfoMessage(message) {
        toaster.push(
            (<Message showIcon type='info'
                closable>
                Info: {message}
            </Message>), {
            placement: 'topCenter',
            duration: 6000
        });
    }

    function showWarningMessage(message) {
        toaster.push(
            (<Message showIcon type='warning'
                closable>
                Warning: {message}!
            </Message>), {
            placement: 'topCenter',
            duration: 6000
        });
    }

    function showErrorMessage(message) {
        toaster.push(
            (<Message showIcon type='error'
                closable>
                Error: {message}
            </Message>), {
            placement: 'topCenter',
            duration: 6000
        });
    }

    return {
        showSuccessMessage,
        showInfoMessage,
        showWarningMessage,
        showErrorMessage
    };
}
