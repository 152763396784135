import { Button } from 'rsuite';
import './404.css'

function NotFound() {
    function historyClick() {
        window.history.back();
    }

    return (
        <div className="notFoundContainer">
            <div className='notFoundContentContainer shadow-lg'>
                <div className='notFoundImage'
                    style={{ backgroundImage: `url(${require('../assets/giphy.gif')})` }}>
                    <div className='notFoundImageHeader'>
                        <h1>404</h1>
                    </div>
                </div>
                <div className='infoContentContainer'>
                    <p>Hmmm, that page doesn't seem to be here...</p>
                    <Button appearance='link'
                        block
                        onClick={() => { historyClick() }}>
                        take me back
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default NotFound;