import './AddAdminUser.css';
import { Input, InputGroup, Divider, TagPicker, Checkbox, ButtonToolbar, Button, Whisper, Tooltip } from 'rsuite';
import React, { useState, useEffect } from 'react';
import { useMessageToaster } from './Messages';
import validatePasswordInput from '../functions/passwordCheck';
import validateEmailInput from '../functions/emailCheck';

import ProfileIcon from '@rsuite/icons/legacy/User';
import EmailIcon from '@rsuite/icons/EmailFill';
import InfoIcon from '@rsuite/icons/legacy/Info';
import PasswordIcon from '@rsuite/icons/legacy/Lock';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import sanitize from '../functions/sanitizeInput';
import api from './api';

function AddAdminUser({ handleRefresh }) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordError, setpasswordError] = useState(null);
    const [permissionList, setPermissionList] = useState([]);
    const [newUserAdmin, setNewUserAdmin] = useState(false);

    const data = [
        'Read',
        'Create',
        'Export',
        'Update user'

    ].map(item => ({
        label: item,
        value: item
    }));

    const {
        showSuccessMessage,
        showErrorMessage,
    } = useMessageToaster();

    const styles = {
        buttonStyles: {
            flex: '1',
            textAlign: 'center'
        },
        errorStyle: {
            borderColor: 'rgb(252, 103, 103)',
        }
    }

    const [visible, setVisible] = React.useState(false);

    function handleUsernameChange(value) {
        setUsername(sanitize(value));
    }

    function handlEmailChange(value) {
        const email = sanitize(value);
        const emailCheck = validateEmailInput(email)
        setEmailError(emailCheck);
        setEmail(email);
    }

    function handlePasswordChange(value) {
        const password = sanitize(value);
        const passwordCheck = validatePasswordInput(password);
        setpasswordError(passwordCheck);
        setPassword(password);
    }

    function handleAdminChange() {
        setNewUserAdmin(!newUserAdmin);
    }
    function handlePermissionChange(value) {
        setPermissionList(value);
    }
    function handlePassword() {
        setVisible(!visible);
    };
    function handleClear() {
        setEmail('');
        setUsername('');
        setPassword('');
        setPermissionList([]);
        setNewUserAdmin(false);
    }

    function handleSubmit() {
        if (!username || !email || !password || !permissionList) {
            showErrorMessage('unable to add user.');
        } else {
            const userObject = {
                username: username,
                email: email,
                password: password,
                permissions: permissionList,
                admin: newUserAdmin
            };
            console.log(userObject);

            fetch(api.baseURL + '/auth/create', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: username,
                    email: email.toLowerCase(),
                    password: password,
                    permissions: permissionList,
                    admin: newUserAdmin
                })
            }).then(response => {
                response.json()
            }).then(responseData => {
                console.log(responseData);
                showSuccessMessage('User created');
                handleRefresh();
                handleClear();
            }).catch((error) => {
                showErrorMessage(error);
                console.error(error);
            });
        }
    };

    return (
        <div className="d-flex">
            <div className="addAdminUserContainer shadow-sm">
                <div className='addAdminUserHeader'>
                    <ProfileIcon />
                    <h5>Add managment user</h5>
                </div>
                <Divider />
                <InputGroup className='inputClass'>
                    <InputGroup.Addon>
                        <EmailIcon />
                    </InputGroup.Addon>
                    <Input placeholder='Username'
                        value={username}
                        onChange={handleUsernameChange} />
                </InputGroup>

                <InputGroup
                    className='inputClass'
                    style={emailError ? styles.errorStyle : null}>
                    <InputGroup.Addon>
                        {emailError ?
                            <Whisper placement='top'
                                speaker={<Tooltip>{emailError}</Tooltip>}>
                                <InfoIcon color='red' />
                            </Whisper> :
                            <EmailIcon />
                        }
                    </InputGroup.Addon>
                    <Input placeholder='Email'
                        value={email}
                        onChange={handlEmailChange} />
                </InputGroup>

                <InputGroup className='inputClass'
                        style={passwordError ? styles.errorStyle : null}>
                        <InputGroup.Addon>
                            {passwordError ?
                                <Whisper placement='top'
                                    speaker={<Tooltip>{passwordError}</Tooltip>}>
                                    <InfoIcon color='red' />
                                </Whisper> :
                                <PasswordIcon />
                            }
                        </InputGroup.Addon>
                        <Input placeholder='Password'
                            value={password}
                            style={passwordError ? styles.errorStyle : null}
                            type={visible ? 'text' : 'password'}
                            onChange={handlePasswordChange}
                        />
                        <InputGroup.Button onClick={handlePassword}>
                            {visible ?
                                <EyeIcon /> :
                                <EyeSlashIcon />
                            }
                        </InputGroup.Button>
                    </InputGroup>

                <Divider>Permissions</Divider>
                <div className='d-flex align-items-center'>
                    <TagPicker data={data}
                        style={{
                            width: '250px'
                        }}
                        value={permissionList}
                        onChange={handlePermissionChange} />
                    <div className='d-flex flex-grow-1 justify-content-center'>
                        <Checkbox onChange={handleAdminChange}
                            checked={newUserAdmin}>
                            Admin
                        </Checkbox>
                    </div>
                </div>

                <div className='buttonContainer'>
                    <ButtonToolbar>
                        <Button appearance='ghost'
                            color='red'
                            style={styles.buttonStyles}
                            onClick={handleClear}>
                            Clear
                        </Button>
                        <Button appearance='primary'
                            style={styles.buttonStyles}
                            onClick={handleSubmit}>
                            Create user
                        </Button>
                    </ButtonToolbar>
                </div>
            </div>
        </div>
    );
}

export default AddAdminUser;