const medicalAidList = ['AECI Medical Aid', 'Alliance Midmed Medical Scheme',
    'Anglo Medical Scheme', 'Anglovaal Group Medical Scheme', 'Bankmed',
    'Barloworld Medical Scheme', 'BestMed Medical Scheme', 'BMW Employees Medical Aid Society',
    'Bonitas Medical Fund', 'BP Medical Aid Society', 'Building and Costruction Ind Med Fund',
    'Cape Medical Plan', 'Chartered Accountants (SA) Med Fund CAMAF',
    'Compcare Wellness Medical Scheme', 'De Beers Befefit Plan', 'Discovery Health Medical Scheme',
    'Engen Medical Benefit Plan', 'Fedhealth Medical Scheme',
    'Fishing Industry Medical Scheme (FISMED)', 'Foodmed Medical Scheme',
    'Genesis Medical Scheme', 'Glencor Medical Scheme', 'Golden Arrows Employees Med Scheme',
    'Government Employees Med Scheme GEMS', 'Health Squared Medical Scheme',
    'Horizon Medical Scheme', 'Impala Medical Plan', 'Imperial and Motus Medical Aid',
    'KeyHealth', 'La-Health Medical Scheme', 'Libcare Medical Scheme', 'Lonmin Medical Scheme',
    'Mokoti Medical Scheme', 'MalKor Medical Scheme', 'Massmart Medical Scheme',
    'MBMed Medical Fund', 'Medihelp', 'Medimed Medical Scheme', 'Medipos Medical Scheme',
    'Medshield Medical Scheme', 'Momentum Medical Scheme', 'Motohealth Care',
    'Multichoice Medical Scheme', 'Netcare Medical Scheme', 'Old Mutual Staff Medical Scheme',
    'Paramed Medical Scheme', 'PG Group Medical Scheme', 'Pick n Pay Medical Scheme',
    'Platinum Health', 'Profmed', 'Rand Water Board Medical Scheme', 'Remedi Medical Scheme', 'Retail Medical Scheme', 'Phodes Univercity Medical Scheme', 'SABC Medical Scheme', 'SAMWUMED',
    'SASOLMED', 'SEDMED', 'Sosonke Health Medical Scheme', 'Sizwe Hosmed Medical Fund', 'South African Breweries Med Scheme', 'South African Police Service Med Scheme (POLMED)', 'Seuremed Health',
    'TFG Medical Scheme', 'Thebemed', 'Tiger Brand Medical Scheme', 'Transmed Medicals Fund', 'Tsogo Sun Group Medical Scheme', 'Umvuso Health Medical Scheme', 'Univercity of KZN Medical Scheme',
    'Witbank Coalfields Medical Scheme', ' Wooltru Healthcare Fund'].map(
        item => ({
            label: item, value: item
        })
    );



const title = [
    'Mr',
    'Mrs',
    'Miss',
    'Ms',
    'Dr'
].map(item => ({
    label: item,
    value: item
}));

const contactOptions = ['Email', 'Call', 'WhatsApp'].map(
    item => ({
        label: item, value: item
    })
);

const race = ['Asian', 'Black', 'Coloured', 'White', 'Indian'].map(
    item => ({
        label: item, value: item
    })
);

const msHistory = ['No', 'Mother', 'Father', 'Sibling', 'Other'].map(
    item => ({
        label: item, value: item
    })
);
const dmtList = [
    'Aubagio (teriflunomide)', 'Avonex (interferon beta-la)', 'Azathioprine (Imuran)',
    'Betaseron (interferon beta-1b)', 'Copaxone (glatiramer acetate)', 'Kesimpta (ofatumumab)', 
    'Lemtrada (alemtuzumab)', 'Mavenclad (cladribine)', 'Mayzent (siponimod)', 
    'Methotrexate', 'Mitoxantrone (Novantrone)', 'Novantrone (mitoxantrone)', 
    'Ocrevus (ocrelizumab)', 'Oral Medications', 'Plegridy (Peginterferon beta-la)', 
    'Ponvory (ponesimod)', 'Rebif (interferon beta-la)', 'Rituximab (Mabthera)', 
    'Tecfidera (dimethyl fumarate)', 'Tuvigin, Gilenya (fingolimod)', 'Tysabri (natalizumab)', 
    'Zeposia (ozanimod)'
].map(item => ({
    label: item, value: item
}));

const IncontinenceList = ['I use incontinence products', 'I use a catheter',
    'I do not have any problems with incontinence', 'Other'].map(
        item => ({
            label: item, value: item
        })
    );

const msList = ['Relapsing remitting MS', 'Secondary progressive MS',
    'Primary progressive MS', 'Clinically isolated MS', 'Not sure', 'Other'].map(
        item => ({
            label: item, value: item
        })
    );

const mobilityList = ['Fully Mobile', 'Partially Mobile', 'Use crutches',
    'Use a walker', 'Use a wheelchair', 'Use an electric wheelchair'].map(
        item => ({
            label: item, value: item
        })
    );

const drivingList = ['I drive a standard vehicle', 'I drive a modified vehicle',
    'I no longer drive due to MS', 'I do not have a driving license',
    'I depened on using Uber or a taxi', 'Other'].map(
        item => ({
            label: item, value: item
        })
    );

const liveArray = ['Spouse/Partner', 'Alone/Independantly',
    'Alone with care support', 'Family support', 'Other'].map(
        item => ({
            label: item, value: item
        })
    );

const province = [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'North West',
    'Northern Cape',
    'Western Cape'
].map(item => ({
    label: item,
    value: item
}));

const countryList = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Anguilla",
    "Antigua &amp; Barbuda", "Argentina", "Armenia", "Aruba", "Australia",
    "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia",
    "Bosnia &amp; Herzegovina", "Botswana", "Brazil", "British Virgin Islands",
    "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon",
    "Cape Verde", "Cayman Islands", "Chad", "Chile", "China", "Colombia",
    "Congo", "Cook Islands", "Costa Rica", "Cote D Ivoire", "Croatia",
    "Cruise Ship", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti",
    "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands",
    "Fiji", "Finland", "France", "French Polynesia", "French West Indies", "Gabon",
    "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland",
    "Grenada", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea Bissau", "Guyana",
    "Haiti", "Honduras", "Hong Kong", "Hungary", "Iceland", "India", "Indonesia",
    "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan",
    "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Kyrgyz Republic", "Laos",
    "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia",
    "Maldives", "Mali", "Malta", "Mauritania", "Mauritius", "Mexico", "Moldova",
    "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique",
    "Namibia", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia",
    "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan",
    "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
    "Poland", "Portugal", "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia",
    "Rwanda", "Saint Pierre &amp; Miquelon", "Samoa", "San Marino", "Satellite",
    "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore",
    "Slovakia", "Slovenia", "South Africa", "South Korea", "Spain", "Sri Lanka",
    "St Kitts &amp; Nevis", "St Lucia", "St Vincent", "St. Lucia", "Sudan", "Suriname",
    "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania",
    "Thailand", "Timor L'Este", "Togo", "Tonga", "Trinidad &amp; Tobago", "Tunisia",
    "Turkey", "Turkmenistan", "Turks &amp; Caicos", "Uganda", "Ukraine",
    "United Arab Emirates", "United Kingdom", "Uruguay", "Uzbekistan", "Venezuela",
    "Vietnam", "Virgin Islands (US)", "Yemen", "Zambia", "Zimbabwe",
].map(item => ({
    label: item,
    value: item
}));

const reason = [
    'Family member',
    'Professional Carer',
    'Interested in MS',
    'Other',
].map(item => ({
    label: item,
    value: item
}));

const DEFAUL_USER_INFO = {
    title: '',
    phone_number: '',
    name: '',
    surname: '',
    email: '',
    password: '',
    id_number: '',
    location: '',
    province: '',
    has_ms: false,
    year_diagnosed: '',
    reason: '',
    date_of_birth: null,
    country_of_birth: '',
    ethnicity: '',
    history_ms: '',
    live_with: '',
    mobility: '',
    driving: '',
    incontinence_issues: '',
    type_ms: '',
    on_medical_plan: '',
    access_cronic_medication: false,
    goverment_clinic_hospital: false,
    facility_recieved: '',
    on_dmt: '',
    year_dmt_start: null,
    other_medication: '',
    medication_score: 0,
    receive_newsletter: false,
    contact_method: 'Email',
    contact_me: false,
    contact_reason: ''
};

export default {
    medicalAidList,
    title,
    contactOptions,
    race,
    msHistory,
    dmtList,
    IncontinenceList,
    msList,
    mobilityList,
    liveArray,
    drivingList,
    province,
    countryList,
    reason,
    DEFAUL_USER_INFO
}