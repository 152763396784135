import '../User/userLogin.css';

import InfoIcon from '@rsuite/icons/legacy/Info';
import UsernameIcon from '@rsuite/icons/EmailFill';
import PasswordIcon from '@rsuite/icons/legacy/Lock';
import PasswordEyeIcon from '@rsuite/icons/legacy/Eye';
import PasswordEyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser, setLoggedIn, setLoggedOut } from '../../store/loginSlice';
import { InputGroup, Input, Button, Whisper, Tooltip, Divider } from 'rsuite';
import { useMessageToaster } from '../Messages';

import validateEmailInput from '../../functions/emailCheck';
import validatePasswordInput from '../../functions/passwordCheck';
import sanitize from '../../functions/sanitizeInput';
import api from '../api';

function Login() {
    const dispatch = useDispatch();
    const navigator = useNavigate();

    const [passwordValue, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setpasswordError] = useState(null);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);

    const {
        showErrorMessage,
        showSuccessMessage
    } = useMessageToaster();

    const inputStyle = {
        width: '80%',
        marginTop: '20px'
    };

    function handleShowPassword() {
        setShowPassword(!showPassword);
    }

    function handlePasswordChange(value) {
        const password = sanitize(value);
        const passwordCheck = validatePasswordInput(password);
        setpasswordError(passwordCheck);
        setPassword(password);
    }

    function handlEmailChange(value) {
        const email = sanitize(value);
        const emailCheck = validateEmailInput(email)
        setEmailError(emailCheck);
        setEmail(email);
    }

    function handleSubmit() {
        fetch(api.baseURL + '/auth/userlogin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email.toLowerCase(),
                password: passwordValue
            })
        }).then(response => response.json())
            .then(responseData => {
                if (responseData.message !== 'Success') {
                    dispatch(setLoggedOut());
                    showErrorMessage(responseData.message);
                    return;
                }
                showSuccessMessage('Logged in');
                localStorage.setItem('token', responseData.token);
                localStorage.setItem('email', email);
                dispatch(setUser(responseData.userObject));
                dispatch(setLoggedIn());
                navigator('/');
            })
            .catch((error) => {
                dispatch(setLoggedOut());
                console.error(error);
            });
    }

    return (
        <div className='loginContainer shadow'>
            <div className='loginContentContainer'>
                <div className='inputContainer'>
                    <div className='titleContainer'>
                        <h3>Login</h3>
                        <Divider style={{
                            width: '60%'
                        }}>OR</Divider>
                        <p>Don't have an account? <a href='/signup'>Sign up</a></p>
                    </div>
                    <InputGroup inside
                        style={inputStyle}>
                        <InputGroup.Addon>
                            {emailError ?
                                <Whisper placement='top'
                                    speaker={<Tooltip>{emailError}</Tooltip>}>
                                    <InfoIcon color='red' />
                                </Whisper> :
                                <UsernameIcon />
                            }
                        </InputGroup.Addon>
                        <Input placeholder='Email'
                            onChange={handlEmailChange}
                            value={email} />
                    </InputGroup>

                    <InputGroup inside
                        style={inputStyle}>
                        <InputGroup.Addon>
                            {passwordError ?
                                <Whisper placement='top'
                                    speaker={<Tooltip>{passwordError}</Tooltip>}>
                                    <InfoIcon color='red' />
                                </Whisper> :
                                <PasswordIcon />
                            }
                        </InputGroup.Addon>
                        <Input placeholder='Password'
                            type={showPassword ? 'text' : 'password'}
                            onChange={handlePasswordChange}
                            value={passwordValue}
                        />
                        <InputGroup.Button onClick={handleShowPassword}> {
                            showPassword ? <PasswordEyeSlashIcon /> :
                                <PasswordEyeIcon />
                        }
                        </InputGroup.Button>
                    </InputGroup>

                    <div className='loginButton'>
                        <Button appearance='primary'
                            onClick={handleSubmit}>
                            Sign in
                        </Button>
                    </div>

                    <div>
                        <Button appearance='link'
                            onClick={() => {
                                navigator('/forgot');
                            }}>
                            Forgot Password?
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;