const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.{8,})/;

const validatePasswordInput = (value) => {
    if (!value) {
        return 'This field is required.'
    } else if (!passwordRegex.test(value)) {
        return 'Please enter a valid password \n 8 Char/ Capital letter, Special char.'
    } else {
        return null;
    }
}

export default validatePasswordInput;