import './App.css';
import { Container, Content, CustomProvider } from 'rsuite';
import { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Navigation from './components/Navigation';
import Admin from './components/Admin';
import Login from './components/Login';
import UserLogin from './components/User/userLogin';

import Protected from './components/Protected';
import Home from './components/Home';
import Profile from './components/Profile';
import Signup from './components/User/Signup';
import UserData from './components/UserData';
import ForgotPassword from './components/ForgotPassword';
import NotFound from './components/404';

function App() {
  const prevLoginState = useRef();
  const [loginState, setLoginState] = useState(false);
  const loggedin = useSelector((state) => state.auth.isLoggedIn);
  const admin = localStorage.getItem('management');

  useEffect(() => {
    if (prevLoginState.current !== loggedin) {
      setLoginState(loggedin);
      prevLoginState.current = loggedin;
    }
  }, [loggedin]);

  return (
    <CustomProvider theme='light'>
      <BrowserRouter>
        <Container>
          {/* {
            loginState ? <Navigation /> : <></>
          } */}
          <Navigation />
          <Content className='contentContainer'>
            <Routes>
              <Route path='/management/'
                element={
                  <Protected children={<UserData />} />
                } />
              {admin ?
                <Route path='/'
                  element={
                    <Protected children={<UserData />} />
                  } /> :
                <Route path='/'
                  element={
                    <Protected children={<Profile />} />
                  } />
              }
              <Route path='/users' />
              <Route path='/management/admin'
                element={
                  <Protected children={<Admin />} />
                } />
              <Route path='/management/login' element={<Login />} />
              <Route path='/login' element={<UserLogin />} />
              <Route path='/forgot' element={<ForgotPassword />} />
              <Route path='/signup' element={<Signup standalone />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </Content>
        </Container>
      </BrowserRouter>
    </CustomProvider>
  );
}

export default App;
