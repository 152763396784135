import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from "react";
import { setLoggedOut, setUser, setLoggedIn } from "../store/loginSlice";
import { Loader } from "rsuite";
import api from "./api";

const Protected = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state) => state.auth);

    const admin = localStorage.getItem('management');
    useEffect(() => {
        const token = localStorage.getItem('token');
        const email = localStorage.getItem('email')?.toLowerCase();

        const url = admin ?
            api.baseURL + '/auth/adminuser' :
            api.baseURL + '/auth/user';

        if (token && email) {
            fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            }).then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error('Not authorized');
                }
            }).then((data) => {
                dispatch(setUser(data.user));
                dispatch(setLoggedIn());
            }).catch((error) => {
                console.error(error);
                dispatch(setLoggedOut());
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            dispatch(setLoggedOut());
            setIsLoading(false);
        }
    }, [dispatch]);

    if (isLoading) {
        return <Loader backdrop
            content='loading...'
            size="md"
            vertical
            speed="slow" />;
    } else if (isLoggedIn) {
        return children;
    } else {
        // const loginUrl = admin ? '/management/login' : '/login';
        return <Navigate to='/management/login' replace />;
    }
};

export default Protected;
