import { Button, Container, Modal, Whisper, Tooltip, Divider, Input, DatePicker, InputGroup, InputPicker, Row, Toggle, Col, MaskedInput, Slider } from "rsuite";
import { useState } from "react";
import InfoIcon from '@rsuite/icons/InfoRound';
import PhoneIcon from '@rsuite/icons/PhoneFill';
import EmailIcon from '@rsuite/icons/EmailFill';
import { useMessageToaster } from './Messages';
import Password from '@rsuite/icons/legacy/Lock';
import PasswordView from '@rsuite/icons/legacy/Eye';
import PasswordNoView from '@rsuite/icons/legacy/EyeSlash';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import DataObjects from './DataObjects';

import './Profile.css';
import validatePasswordInput from "../functions/passwordCheck";
import api from "./api";

const style = {
    rowStyle: {
        display: 'flex',
        marginBottom: '10px'
    },
    dividerStyle: {
        width: 0,
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        marginLeft: '10px',
    },
    errorStyle: {
        borderColor: 'rgb(252, 103, 103)',
        marginBottom: '10px'
    }
}

function Profile() {
    const navigator = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const {
        showInfoMessage,
        showErrorMessage,
        showSuccessMessage
    } = useMessageToaster();

    // Additional details
    const [hasMs, setHasMs] = useState(user.has_ms);
    const [yearDiagnosed, setYearDiagnosed] = useState(user.year_diagnosed ? new Date(user.year_diagnosed): null);
    const [reasonUser, setReasonUser] = useState(user.reason);
    const [idNumber, setIdNumber] = useState(user.id_number);
    const [dateOfBirth, setDateOfBirth] = useState(user.date_of_birth ? new Date(user?.date_of_birth): null);
    const [counrtyOfBirth, setCounrtyOfBirth] = useState(user.country_of_birth);
    const [ethnicityUser, setEthnicityUser] = useState(user.ethnicity);
    const [msHistoryUser, setMsHistoryUser] = useState(user.history_ms);
    const [liveWithUser, setLiveWithUser] = useState(user.live_with);
    const [mobility, setMobility] = useState(user.mobility);
    const [driving, setDriving] = useState(user.driving);
    const [incontinence, setIncontinence] = useState(user.incontinence_issues);
    const [msType, setMsType] = useState(user.type_ms);

    // Medical aid
    const [medicalAid, setMedicalAid] = useState(user.on_medical_plan);
    const [cronicAccess, setCronicAccess] = useState(user.access_cronic_medication);

    // Medication
    const [medicationHospital, setMedicationHospital] = useState(user.type_ms);
    const [medicationFacility, setMedicationFacility] = useState(user.type_ms);
    const [dmtUser, setDmtUser] = useState(user.on_dmt);
    const [dmtStart, setDmtStart] = useState(user.year_dmt_start? new Date(user.year_dmt_start): null);
    const [otherMsMedication, setOtherMsMedication] = useState(user.type_ms);
    const [medicationWorking, setMedicationWorking] = useState(user.medication_score);

    // User details
    const [titleUser, setTitleUser] = useState(user.title);
    const [phoneNumber, setPhoneNumber] = useState(user.phone_number);
    const [name, setName] = useState(user.name);
    const [surname, setSurname] = useState(user.surname);
    const [email, setEmail] = useState(user.email);
    const [location, setLocation] = useState(user.location);
    const [provinceUser, setProvince] = useState(user.province);

    const [medicationHappiness, setMedicationHappiness] = useState(0);
    const [medicalAssistance, setmedicalAssistance] = useState(false);
    const [medicationAssistance, setmedicationAssistance] = useState(false);

    // Preferences
    const [newsletter, setNewsLetter] = useState(user.receive_newsletter);
    const [contactMethod, setContactMethod] = useState(user.contact_method);

    const [contactMe, setContactMe] = useState(user.contact_me);
    const [contactReason, setContactReason] = useState(user.contact_reason);


    const [showModal, setShowModal] = useState(false);
    const token = localStorage.getItem('token');
    const handleConfirm = () => {
        fetch(api.baseURL + '/auth/removeuser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                user: user._id
            })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            return response.json();
        }).then(responseData => {
            console.log(responseData);
            showInfoMessage(responseData.message);
            setShowModal(false);
            navigator('/login');
        }).catch(error => {
            showErrorMessage(error.message);
            console.error(error);
        });
    };

    const handleCancel = () => {
        setShowModal(false);
        showErrorMessage('canceled');
    };

    const marks = {
        0: 'Very bad',
        25: 'Bad',
        50: 'Middle',
        75: 'Good',
        100: 'Very good'
    };

    function handleMsChange() {
        setHasMs(!hasMs);
    }

    function handleMedicalAssistanceChange() {
        setmedicalAssistance(!medicalAssistance);
    }

    async function handleContact(value) {
        try {
            setContactMe(true);
            setContactReason(value);
            const response = await fetch(api.baseURL + '/contact-me', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    user: user._id,
                    details: value
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log(response);
            const responseData = await response.json();

            console.log(responseData);
            showInfoMessage("We'll be in contact shortly");
        } catch (error) {
            showErrorMessage('An error occurred while updating the user.');
            console.error(error);
        }
    }

    function handleMedicationAssistanceChange() {
        setmedicationAssistance(!medicationAssistance);
    }

    const renderMark = (markValue, mark) => {
        return (
            <span key={markValue} style={{ fontWeight: 'bold' }}>
                {mark}
            </span>
        );
    };

    function handleUpdate() {
        const userObject = {
            _id: user._id,
            has_ms: Boolean(hasMs),
            year_diagnosed: yearDiagnosed,
            reason: reasonUser,
            id_number: idNumber,
            date_of_birth: dateOfBirth,
            country_of_birth: counrtyOfBirth,
            ethnicity: ethnicityUser,
            history_ms: msHistoryUser,
            live_with: liveWithUser,
            mobility: mobility,
            driving: driving,
            incontinence_issues: incontinence,
            type_ms: msType,
            on_medical_plan: medicalAid,
            access_cronic_medication: cronicAccess,
            goverment_clinic_hospital: medicationHospital,
            facility_recieved: medicationFacility,
            on_dmt: dmtUser,
            year_dmt_start: dmtStart,
            other_ms_medication: otherMsMedication,
            medication_score: medicationWorking,
            title: titleUser,
            phone_number: phoneNumber,
            name: name,
            surname: surname,
            email: email.toLowerCase(),
            location: location,
            province: provinceUser,
            medication_happiness: medicationHappiness,
            medical_assistance: medicalAssistance,
            medication_assistance: medicationAssistance,
            receive_newsletter: newsletter,
            contact_method: contactMethod,
            contact_me: contactMe,
            contact_reason: contactReason
        };

        fetch(api.baseURL + '/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                user: userObject
            })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            return response.json();
        }).then(responseData => {
            if (responseData.message === 'no new records found') {
                showInfoMessage(responseData.message);
            } else {
                showSuccessMessage(responseData.message);
            }
        }).catch(error => {
            showErrorMessage(error.message);
            console.error(error);
        });
    }

    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const [passwordValue, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setpasswordError] = useState(null);
    const [confirmPasswordValue, setConfirmPassword] = useState('');
    const [confirmShowPassword, setConfirmShowPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);

    function handleModalClose() {
        setModalOpen(false);
    }

    function handleOpenModal(task) {
        const title = task === 'reset' ? 'Reset password' : 'Delete user';
        const modalObject = {
            title: title,
            task: task,
        }
        setModalData(modalObject);
        setModalOpen(true);
    }

    function handleDeleteClick() {
        fetch(api.baseURL + '/auth/remove-user', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user: user,
            })
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok.');
            }
        }).then((data) => {
            showSuccessMessage(data.message);
            navigator('/login');
            handleModalClose();
        }).catch((error) => console.log(error));
    }

    function handleResetClick() {
        setpasswordError(!passwordValue);
        setConfirmPasswordError(!confirmPasswordValue);

        if (passwordError || confirmPasswordError) {
            showErrorMessage('Please fix all errors');
        } else {
            fetch(api.baseURL + '/auth/password-reset', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: user._id,
                    password: passwordValue
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            }).then((data) => {
                showSuccessMessage(data.message);
                handleModalClose();
            }).catch((error) => console.log(error));
        }
    }

    function handleReset() {
        handleOpenModal('reset');
    }

    function handleDeleteUser() {
        handleOpenModal('delete');
    }

    function handleShowPassword() {
        setShowPassword(!showPassword);
    }
    function handlePasswordChange(value) {
        const passwordCheck = validatePasswordInput(value);
        setpasswordError(passwordCheck);
        setPassword(value);
    }

    function handleConfirmShowPassword() {
        setConfirmShowPassword(!confirmShowPassword);
    }
    function handleConfirmPasswordChange(value) {
        setConfirmPasswordError(value !== passwordValue);
        setConfirmPassword(value);
    }

    return (
        <>
            <div className="profileContainer">

                <Modal open={modalOpen}
                    onClose={handleModalClose}
                    size='xs'>
                    <Modal.Header>
                        {modalData?.title}
                    </Modal.Header>
                    <Modal.Body>
                        {modalData?.task === 'reset' ?
                            <div style={{
                                width: '250px',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                paddingTop: '20px',
                                paddingBottom: '20px'
                            }}>
                                <InputGroup inside
                                    style={passwordError ? style.errorStyle : style.rowStyle}>
                                    <InputGroup.Addon>
                                        {passwordError ?
                                            <Whisper placement='top'
                                                speaker={<Tooltip>Please enter a valid password \n 8 Char/ Capital letter, Special char.</Tooltip>}>
                                                <InfoIcon color='red' />
                                            </Whisper> :
                                            <Password />
                                        }
                                    </InputGroup.Addon>
                                    <Input placeholder='Password'
                                        value={passwordValue}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={handlePasswordChange}
                                    />
                                    <InputGroup.Button onClick={handleShowPassword}>
                                        {showPassword ?
                                            <PasswordNoView /> :
                                            <PasswordView />
                                        }
                                    </InputGroup.Button>
                                </InputGroup>

                                <InputGroup inside
                                    style={confirmPasswordError ? style.errorStyle : style.rowStyle}>
                                    <InputGroup.Addon>
                                        {confirmPasswordError ?
                                            <Whisper placement='top'
                                                speaker={<Tooltip>Passwords do not match.</Tooltip>}>
                                                <InfoIcon color='red' />
                                            </Whisper> :
                                            <Password />
                                        }
                                    </InputGroup.Addon>
                                    <Input placeholder='Confirm password'
                                        value={confirmPasswordValue}
                                        type={confirmShowPassword ? 'text' : 'password'}
                                        onChange={handleConfirmPasswordChange}
                                    />
                                    <InputGroup.Button onClick={handleConfirmShowPassword}>
                                        {confirmShowPassword ?
                                            <PasswordNoView /> :
                                            <PasswordView />
                                        }
                                    </InputGroup.Button>
                                </InputGroup>
                            </div> :
                            <p>Are you sure you want to delete user:
                                <span className='highlight'>
                                    {modalData?.data?.username}
                                </span>
                            </p>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        {modalData?.task === 'reset' ?
                            <div>
                                <Button onClick={handleModalClose} appearance="subtle">
                                    Cancel
                                </Button>
                                <Button onClick={handleResetClick} appearance="primary">
                                    Reset
                                </Button>
                            </div> :
                            <div>
                                <Button onClick={handleModalClose} appearance="subtle">
                                    Cancel
                                </Button>
                                <Button onClick={handleDeleteClick} appearance="ghost"
                                    color='red'>
                                    Delete
                                </Button>
                            </div>
                        }
                    </Modal.Footer>
                </Modal>

                <div className="profileButtonGroup">
                    <div>
                        <Button appearance="ghost"
                            color="red"
                            onClick={handleDeleteUser}>Delete profile</Button>
                        <Button appearance="primary"
                            onClick={handleUpdate}>Save</Button>
                    </div>
                </div>
                <Divider style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} />
                <Row>
                    {/* COLUMN ONE */}
                    <Col xs={24} md={12} lg={8}>
                        <Row>
                            <Container className="profileUserContainer shadow-sm">
                                <Container className="profileAdditionalContainer">
                                    <Divider>Additional Details</Divider>
                                    <Row style={style.rowStyle}>
                                        <div className="profileMsQuestion">
                                            <p>Do you have Ms? </p>
                                            <div>
                                                <Toggle checkedChildren='Yes'
                                                    unCheckedChildren='No'
                                                    checked={hasMs}
                                                    onClick={handleMsChange} />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <DatePicker format="yyyy-MM"
                                            placeholder='Year diagnosed'
                                            ranges={[]}
                                            disabled={!hasMs}
                                            value={yearDiagnosed}
                                            onChange={setYearDiagnosed} />
                                        <Divider vertical
                                            style={style.dividerStyle} />
                                        <InputPicker placeholder='Interest in MS'
                                            data={DataObjects.reason}
                                            disabled={hasMs}
                                            value={reasonUser}
                                            onChange={setReasonUser}
                                            style={{ flexGrow: 1 }} />
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <MaskedInput placeholder="ID Number"
                                            value={idNumber}
                                            onChange={setIdNumber}
                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]} />
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex">
                                            <DatePicker placeholder='Date of birth'
                                                value={dateOfBirth}
                                                onChange={setDateOfBirth} />
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker placeholder='Country of birth'
                                                data={DataObjects.countryList}
                                                searchable
                                                value={counrtyOfBirth}
                                                onChange={setCounrtyOfBirth}
                                                style={{ flexGrow: 1 }} />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <InputGroup>
                                            <InputGroup.Addon>
                                                <Whisper placement='top'
                                                    speaker={
                                                        <Tooltip>We apologise for asking this but it is important statitcally due to the rapidly changing demographics of MS in South Africa</Tooltip>
                                                    }>
                                                    <InfoIcon />
                                                </Whisper>
                                            </InputGroup.Addon>
                                            <InputPicker placeholder='Ethnicity'
                                                data={DataObjects.race}
                                                value={ethnicityUser}
                                                onChange={setEthnicityUser}
                                                searchable
                                                style={{ flexGrow: 1 }} />
                                        </InputGroup>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>History of MS:</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker placeholder='No'
                                                data={DataObjects.msHistory}
                                                cleanable={false}
                                                value={msHistoryUser}
                                                onChange={setMsHistoryUser}
                                                style={{ flex: 1 }} />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>I live with:</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker
                                                data={DataObjects.liveArray}
                                                value={liveWithUser}
                                                onChange={setLiveWithUser}
                                                style={{ flex: 2 }} />
                                        </div>
                                    </Row>

                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>Mobility:</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker value={mobility}
                                                onChange={setMobility}
                                                data={DataObjects.mobilityList}
                                                style={{ flex: 2 }} />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>Driving:</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker data={DataObjects.drivingList}
                                                value={driving}
                                                onChange={setDriving} />
                                        </div>
                                    </Row>
                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>Incontinence issues:</p>
                                        <div className="d-flex align-items-center">
                                            <InputPicker data={DataObjects.IncontinenceList}
                                                style={{ width: '100%' }}
                                                value={incontinence}
                                                onChange={setIncontinence} />
                                        </div>
                                    </Row>
                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>What type of MS do you have?</p>
                                        <div className="d-flex align-items-center">
                                            <InputPicker data={DataObjects.msList}
                                                style={{ width: '100%' }}
                                                value={msType}
                                                onChange={setMsType} />
                                        </div>
                                    </Row>
                                </Container>
                            </Container>
                        </Row>

                    </Col>

                    {/* COLUMN TWO */}

                    <Col xs={24} md={12} lg={8}>
                        <Row style={style.rowStyle}>
                            <Container className="profileUserContainer shadow-sm">
                                <Container style={{ padding: '10px' }}>
                                    <Divider style={{ width: '100%' }}>Medical aid</Divider>

                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>Are you on a medical aid plan?</p>
                                        <div className="d-flex align-items-center">
                                            <InputPicker data={DataObjects.medicalAidList}
                                                searchable
                                                style={{ width: '100%' }}
                                                value={medicalAid}
                                                onChange={setMedicalAid} />
                                        </div>
                                    </Row>
                                    <Row style={{ ...style.rowStyle, display: 'flex', alignItems: 'center' }}>
                                        <p style={{ marginBottom: '5px', width: '80%' }}>Are you able to access cronic medication with your medical aid?</p>
                                        <Toggle checkedChildren='Yes'
                                            unCheckedChildren='No'
                                            checked={cronicAccess}
                                            onClick={() => { setCronicAccess(!cronicAccess) }} />
                                    </Row>
                                    <Row style={{ ...style.rowStyle, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <p style={{ marginBottom: '5px' }}>Would you like assistance with this?</p>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Button appearance="ghost"
                                                disabled={cronicAccess}
                                                onClick={() => { handleContact('Cronic Access Assistance') }}>
                                                Contact me
                                            </Button>
                                        </div>
                                    </Row>
                                </Container>
                            </Container>
                        </Row>
                        <Row style={style.rowStyle}>
                            <Container className="profileUserContainer shadow-sm">
                                <Container style={{ padding: '10px' }}>
                                    <Divider style={{ width: '100%' }}>Medication</Divider>
                                    <Row style={{
                                        ...style.rowStyle,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <p style={{
                                            marginBottom: '5px',
                                            width: '80%'
                                        }}>
                                            Do you receive medication from a goverment clinic or hospital?
                                        </p>
                                        <Toggle checkedChildren='Yes'
                                            unCheckedChildren='No'
                                            checked={medicationHospital}
                                            onClick={() => { setMedicationHospital(!medicationHospital) }} />
                                    </Row>

                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>Which facility do you draw your medication from?</p>
                                        <div className="d-flex align-items-center">
                                            <Input style={{ width: '100%' }}
                                                value={medicationFacility}
                                                onChange={setMedicationFacility}
                                                disabled={!medicationHospital} />
                                        </div>
                                    </Row>

                                    <Row style={{ ...style.rowStyle, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <p style={{ marginBottom: '5px' }}>Would you like assistance with this?</p>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Button appearance="ghost"
                                                disabled={medicationHospital}
                                                onClick={() => { handleContact('Receive medication from goverment clinic/hospital Assistance') }}>
                                                Contact me
                                            </Button>
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>
                                                Are you on a DMT?
                                            </p>
                                            <Whisper placement='top'
                                                speaker={
                                                    <Tooltip>Disease Modifying Therapy</Tooltip>
                                                }>
                                                <InfoIcon />
                                            </Whisper>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker cleanable={false}
                                                style={{ flex: 1 }}
                                                value={dmtUser}
                                                onChange={setDmtUser}
                                                data={DataObjects.dmtList} />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>Which year did you start with DMT's?</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <DatePicker format="yyyy-MM"
                                                value={dmtStart}
                                                onChange={setDmtStart} />
                                        </div>
                                    </Row>
                                    {/* <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <p style={{ flex: 1, margin: 0 }}>Do you take other medication for MS?</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                        </div>
                                    </Row> */}
                                    {/* <InputPicker value={otherMsMedication}
                                        onChange={setOtherMsMedication}
                                        style={{ width: '100%', marginBottom: '10px' }} /> */}
                                    <Row style={{ ...style.rowStyle, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginBottom: '5px' }}>How well is your current medication working for you?</p>
                                        <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}>
                                            
                                            <Slider min={0} max={100}
                                                step={25}
                                                graduated
                                                marks={marks}
                                                value={medicationWorking}
                                                onChange={setMedicationWorking}
                                                renderMark={renderMark} />
                                        </div>
                                    </Row>
                                </Container>
                            </Container>
                        </Row>
                    </Col>

                    {/* COLUMN THREE */}

                    <Col xs={24} md={12} lg={8}>
                        <Row style={style.rowStyle}>
                            <Container className="profileUserContainer shadow-sm">
                                <Container className="profileUserData">
                                    <Divider>User Details</Divider>
                                    <Row style={style.rowStyle}>
                                        <InputPicker placeholder='Title'
                                            cleanable={false}
                                            style={{
                                                width: '120px',
                                            }}
                                            value={titleUser}
                                            data={DataObjects.title}
                                            onChange={setTitleUser} />
                                        <Divider vertical
                                            style={style.dividerStyle} />
                                        <InputGroup style={{
                                            width: '180px'
                                        }}>
                                            <InputGroup.Addon>
                                                <PhoneIcon />
                                            </InputGroup.Addon>
                                            <MaskedInput placeholder='Cell No.'
                                                guide={false}
                                                value={phoneNumber}
                                                onChange={setPhoneNumber}
                                                mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]} />
                                        </InputGroup>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <Input placeholder="Name"
                                            value={name}
                                            onChange={setName}
                                            style={{ width: '150px' }} />
                                        <Divider vertical style={style.dividerStyle} />
                                        <Input placeholder="Surname"
                                            value={surname}
                                            onChange={setSurname} />
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <InputGroup>
                                            <InputGroup.Addon><EmailIcon /></InputGroup.Addon>
                                            <Input placeholder="Email"
                                                value={email}
                                                onChange={setEmail} />
                                        </InputGroup>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <Input placeholder="Town/City"
                                            value={location}
                                            onChange={setLocation} />
                                        <Divider vertical
                                            style={style.dividerStyle} />
                                        <InputPicker placeholder="Province"
                                            data={DataObjects.province}
                                            cleanable={false}
                                            style={{ width: '280px' }}
                                            value={provinceUser}
                                            onChange={setProvince} />
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex w-100 justify-content-end align-items-center">
                                            <Button appearance="ghost"
                                                color="red"
                                                onClick={() => { handleReset() }}>Change password</Button>
                                        </div>
                                    </Row>
                                </Container>
                            </Container>
                        </Row>
                        <Row>
                            <Container className="profileUserContainer shadow-sm">
                                <Container className="profilePreferenceContainer">
                                    <Divider>Preferences</Divider>
                                    <Row>
                                        <div className="profileMonthlyQuestion">
                                            <p style={{ width: '80%' }}>Would you like to recieve the monthly newsletter?</p>
                                            <Toggle checkedChildren='Yes'
                                                unCheckedChildren='No'
                                                checked={newsletter}
                                                onChange={setNewsLetter}
                                            />
                                        </div>
                                    </Row>
                                    <Row style={style.rowStyle}>
                                        <div className="d-flex align-items-center w-100">
                                            <p style={{ flex: 1, margin: 0 }}>Prefered method of contact?</p>
                                            <Divider vertical
                                                style={style.dividerStyle} />
                                            <InputPicker data={DataObjects.contactOptions}
                                                cleanable={false}
                                                value={contactMethod}
                                                onChange={setContactMethod} />
                                        </div>
                                    </Row>
                                    <Row style={{ ...style.rowStyle, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <p style={{ marginBottom: '5px' }}>Need help?</p>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Button appearance="primary"
                                                color="green"
                                                disabled={medicalAssistance}
                                                onClick={() => { handleContact('General Assistance') }}
                                            >Contact me</Button>
                                        </div>
                                    </Row>
                                </Container>
                            </Container>
                        </Row>
                    </Col>
                </Row>

            </div>
        </>
    );
}

export default Profile;